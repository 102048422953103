import React, { useEffect } from 'react'
import SearchBar from '../components/general/SearchBar'
import SupportHero from '../components/support/SupportHero'
import SupportContent from '../components/support/SupportContent'
import SupportFaq from '../components/support/SupportFaq'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import SupportContact from '../components/support/SupportContact'
import { useLocation } from 'react-router-dom'
import MetaTags from '../MetaTags'


const SuppportPage = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sectionId]);

  return (
    <main className='flex flex-col items-center justify-center w-full font-inter'>
        <MetaTags
         title="" 
         description="" 
         link="/suppportPage"
          />
         <SupportHero supportTab='text-[#6FC400]' />
         <SearchBar />
         <SupportContent />
         <SupportFaq />
         <SupportContact />
         <Subscribe />
         <Footer />
    </main>
  )
}

export default SuppportPage
