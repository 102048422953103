import React, { useEffect } from 'react'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import AboutHero from '../components/whoWeAre/AboutHero'
import AboutContent1 from '../components/whoWeAre/AboutContent1'
import AboutContent2 from '../components/whoWeAre/AboutContent2'
import AboutContent3 from '../components/whoWeAre/AboutContent3'
import { useLocation } from 'react-router-dom'
import MetaTags from '../MetaTags'


const WhoWeAre = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);

  return (
    <main className='flex flex-col items-center justify-center w-full font-inter'>
         <MetaTags
         title="Who we are" 
         description="" 
         link="/whoWeAre"
          />
         <AboutHero aboutTab='text-[#6FC400]' />
         <AboutContent1 />
         <AboutContent2 />
         <AboutContent3 />
         <Subscribe />
         <Footer />
    </main>
  )
}

export default WhoWeAre
