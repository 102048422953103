import React, { useEffect } from 'react'
import AppDev from '../../assets/images/appDevelopment.png'
import NetService from '../../assets/images/NetServices.png'
import ManagedIt from '../../assets/images/managedIt.png'
import TelecomSer from '../../assets/images/TelecomServices.png'
import AppDevIcon1 from '../../assets/icons/appDev1.svg'
import ItIcon1 from '../../assets/icons/it1.svg'
import NetIcon1 from '../../assets/icons/net1.svg'
import TelIcon1 from '../../assets/icons/tel1.svg'
import arrow from '../../assets/icons/ArrowLeft.svg'
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";




const ServicesContent = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

  return (
    <main className='flex flex-col items-start justify-start w-full font-sans px-5 pb-8 md:pb-12 md:px-8 lg:px-16 lg:pb-16 xl:px-24 xl:pb-24 max-w-[1440px]'>

       <div className='flex flex-col items-start w-full mt-9 md:mt-10 lg:mt-11 xl:mt-16'>
          <p className='text-base font-normal text-[#130F26] md:text-lg lg:text-2xl xl:text-3xl'>
             What we do for you at Morgen Green
          </p>
          <p className='text-xs font-normal text-[#7B8794] font-inter mt-3 leading-6 lg:mt-5 xl:mt-7 xl:text-[15px] xl:leading-7'>
               Morgen Green provides a range of comprehensive tech solutions designed to address diverse business challenges. Our Application Services create value by 
               designing customer journeys, rationalizing application portfolios, and managing cybersecurity risks. As a trusted partner in Network Transformation and Management, we leverage new technologies to deliver efficient solutions. With extensive expertise in the telecom industry, we deliver the latest technological advances to consumers globally. Additionally, our Managed IT Services include the setup and maintenance of systems, offering tailored solutions to meet specific project requirements.
          </p>
       </div>

                   {/* CARDS CONTAINER */}
       <div className='flex flex-col items-start justify-start w-full font-sans mt-9 md:flex-row md:items-center md:justify-between md:mt-12 lg:mt-14 xl:mt-16'>
            {/* LEFT CARDS */}
           <div className='flex flex-col items-center justify-start w-full lg:w-[45%] xl:w-[42%]'>
                <Link to='/appDev' 
                className='relative flex flex-col items-start justify-center w-[335px] lg:w-full'
                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in"
                >
                   <img className='w-full h-[222px] object-cover lg:h-full'
                   src={AppDev} alt='app-development' loading='lazy' />
               
                   <div className='flex flex-col items-start justify-start absolute top-0 px-6 z-20 mt-8 w-full lg:px-10 lg:mt-11 xl:px-14 xl:mt-16'>
                       <div className='flex items-center justify-start'>
                           <img className='w-[17px] lg:w-[23px] xl:w-7' src={AppDevIcon1} alt='appDev' />
                           <p className='text-sm text-white pl-3 lg:text-xl lg:pl-4 xl:text-[26px] xl:pl-5'>App Development</p>
                       </div>

                       <p className='text-[11px] text-white leading-5 font-light mt-2.5 lg:text-xs lg:leading-[21px] lg:pt-1 xl:text-sm xl:leading-8 xl:mt-4'>
                           Our Application Services creates value by addressing your challenges – from designing customer journeys to rationalizing application portfolios to 
                           managing cyber security risks.
                       </p>
    
                       <div className='flex items-center justify-start mt-2 lg:mt-3 xl:mt-4'>
                           <p className='text-[11px] text-[#77C214] lg:text-xs xl:text-sm'>Explore more</p>
                           <img className='ml-1.5 xl:w-10' src={arrow}  alt='arrow'/>
                       </div>
                   </div>
    
                </Link>


                <Link to='/netServices' 
                className='relative flex flex-col items-start justify-center w-[335px] -mt-10 lg:w-full xl:-mt-14'
                data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-out"
                >
                   <img className='w-full h-[222px] object-cover lg:h-full'
                   src={NetService} alt='app-development' loading='lazy' />
               
                   <div className='flex flex-col items-start justify-start absolute top-0 px-6 z-20 mt-[75px] w-full lg:px-10 lg:mt-24 xl:px-14 xl:mt-32'>
                       <div className='flex items-center justify-start'>
                           <img className='w-[22px] lg:w-[29px] xl:w-9' src={NetIcon1} alt='appDev' />
                           <p className='text-sm text-white pl-3 lg:text-xl lg:pl-4 xl:text-[26px] xl:pl-5'>Network Services</p>
                       </div>

                       <p className='text-[11px] text-white leading-5 font-light mt-2.5 lg:text-xs lg:leading-[21px] lg:pt-1 xl:text-sm xl:leading-8 xl:mt-4'>
                           Our Network Service is a trusted partner at the forefront of Network Transformation as well as Network Management leveraging new technologies and 
                           delivering them efficiently.
                       </p>
    
                       <div className='flex items-center justify-start mt-2 lg:mt-3 xl:mt-4'>
                           <p className='text-[11px] text-[#77C214] lg:text-xs xl:text-sm'>Explore more</p>
                           <img className='ml-1.5 xl:w-10' src={arrow}  alt='arrow'/>
                       </div>
                   </div>
    
                </Link>
            </div>



            {/* RIGHT CARDS */}
           <div className='flex flex-col items-center justify-start w-full mt-5 md:items-end md:mt-0 lg:w-[51.5%] xl:w-[54%]'>
                <Link to='/support' 
                className='relative flex flex-col items-start justify-center w-[335px] lg:w-full'
                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in"
                >
                   <img className='w-full h-[195px] object-cover rounded-3xl border-[2px] border-[#77C214] md:h-[205px] lg:h-[280px] lg:rounded-[40px] xl:h-[340px]'
                   src={ManagedIt} alt='app-development' loading='lazy' />
               
                   <div className='flex flex-col items-start justify-start absolute top-0 px-6 z-20 mt-6 w-full lg:px-10 lg:mt-[60px] xl:px-14 xl:mt-20'>
                       <div className='flex items-center justify-start'>
                           <img className='w-[17px] lg:w-[23px] xl:w-7' src={ItIcon1} alt='appDev' />
                           <p className='text-sm text-white pl-3 lg:text-xl lg:pl-4 xl:text-[26px] xl:pl-5'>Managed IT/Support</p>
                       </div>

                       <p className='text-[11px] text-white leading-5 font-light mt-2.5 lg:text-xs lg:leading-[21px] lg:pt-1 xl:text-sm xl:leading-8 xl:mt-4'>
                          Our Managed IT Services include the setup and maintenance of systems of any kind. We offer a selection of managed servers for simple use-cases – 
                          individual projects can be realized according to your requirements.
                       </p>
    
                       <div className='flex items-center justify-start mt-2 lg:mt-3 xl:mt-4'>
                           <p className='text-[11px] text-[#77C214] lg:text-xs xl:text-sm'>Explore more</p>
                           <img className='ml-1.5 xl:w-10' src={arrow}  alt='arrow'/>
                       </div>
                   </div>
    
                </Link>

                
                <Link to='/telecomServices' 
                className='relative flex flex-col items-start justify-center w-[335px] mt-5 md:items-end lg:w-full lg:mt-8 xl:mt-10'
                data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in"
                >
                   <img className='w-full h-[175px] object-cover rounded-3xl border-[2px] border-[#77C214] md:h-[175px] lg:h-[180px] lg:rounded-[40px] xl:h-[255px]'
                   src={TelecomSer} alt='app-development' loading='lazy' />
               
                   <div className='flex flex-col items-start justify-start absolute top-0 px-6 z-20 mt-6 w-full lg:px-10 lg:mt-6 xl:px-14 xl:mt-10'>
                       <div className='flex items-center justify-start'>
                           <img className='w-[21px] lg:w-[28px] xl:w-[34px]' src={TelIcon1} alt='appDev' />
                           <p className='text-sm text-white pl-3 lg:text-xl lg:pl-4 xl:text-[26px] xl:pl-5'>Telecommunication Services</p>
                       </div>

                       <p className='text-[11px] text-white leading-5 font-light mt-2.5 lg:text-xs lg:leading-[21px] lg:pt-1 xl:text-sm xl:leading-8 xl:mt-4'>
                          Morgen Green’s global network is built on several years of expertise in the telecom industry and is dedicated to delivering the latest technological 
                          advances to consumers around the world.
                       </p>
    
                       <div className='flex items-center justify-start mt-2 lg:mt-3 xl:mt-4'>
                           <p className='text-[11px] text-[#77C214] lg:text-xs xl:text-sm'>Explore more</p>
                           <img className='ml-1.5 xl:w-10' src={arrow}  alt='arrow'/>
                       </div>
                   </div>
    
                </Link>
                

            </div>

       </div>

       <div className='flex justify-center w-full mt-9 md:mt-14 lg:mt-16 xl:mt-[90px]'>
            <Link to={{ pathname: '/suppportPage', search: '?sectionId=LearnMore'}} 
            className='flex items-center justify-center bg-[#77C214] text-white text-[13px] w-full font-inter h-12 rounded md:w-[65%] lg:w-[70%] xl:w-[65%] xl:h-16 
                       xl:text-base'>
                 Get in touch
            </Link>
       </div>

    </main>
  )
}

export default ServicesContent
