import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'


const SupportFaq = () => {
    const [open, setOpen] = useState([]);

    const faqs = [
        {
            id: 1,
            title: `What services does Morgen Green offer?`,
            desc: `Morgen Green offers a wide range of tech solutions including software development, IT consulting, Enterprise Application Development, 
                   network and communication services, managed IT services, and Telecommunication Services.`
        },
        {
            id: 2,
            title: `How can Morgen Green help my business?`,
            desc: `We provide customized technology solutions designed to improve efficiency, enhance security, and drive innovation in your business. 
                   Our expert team works closely with you to understand your needs and deliver tailored services that support your business goals.`
        },
        {
            id: 3,
            title: `How experienced is your team?`,
            desc: `Our technicians and consultants have many years of experience in the tech industry. They are skilled in the latest technologies and best practices, 
                   ensuring high-quality service and support for your business.`
        },
        {
            id: 4,
            title: `What is the process for starting a project with Morgen Green?`,
            desc: `Starting a project with us is straightforward. Contact us to schedule a consultation, where we’ll discuss your needs and objectives. We then create a 
                   customized proposal and, once agreed upon, our team begins the implementation process.`
        },
        {
            id: 5,
            title: `Do you offer 24/7 support?`,
            desc: `Yes, we offer 24/7 support to ensure that your systems and networks are always running smoothly. Our team is available around the clock, 365 days a year, 
                   to address any issues that may arise.`
        },
    ]

    const handleFaq = (item) => {
        setOpen((prevState) =>
          prevState.includes(item)
            ? prevState.filter((tabId) => tabId !== item)
            : [...prevState, item]
        );
    
      }

  return (
    <main className='flex flex-col items-start justify-start w-full font-inter px-5 mt-6 md:px-8 md:mt-14 lg:px-16 lg:mt-16 xl:px-24 xl:mt-20 max-w-[1440px]'>
        {/* ICON */}
         <div className='flex items-center justify-start'>
             <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
             <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
         </div>

         {/* DESCRIPTION */}
         <div className='flex flex-col items-center justify-start md:items-start'>
             <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full pt-2.5 lg:text-[17px] xl:text-2xl'>
                  Frequently Asked Questions
             </p>

             <p className='text-[12px] text-[#7B8794] font-normal leading-[26px] pt-3.5 lg:leading-[22px] xl:text-[16px] xl:leading-8 xl:pt-6'>
                  Find Answers to Common Questions About Our Services, Solutions, and Support.
             </p>
         </div>

         {/* FAQ CONTAINER */}
         <div className='flex flex-col items-center w-full mt-3 md:mt-6 xl:w-[100%]'>
              {faqs.map((item) => (
              <div key={item.id} className={`flex flex-col items-center w-full my-1.5 py-2 md:py-4 xl:my-2 ${open.includes(item.id) && 'border-[1px] border-[#D0D5DD] rounded-xl mt-4'}`}>
                  <div onClick={()=>handleFaq(item.id)}
                      className='flex items-center justify-between w-full px-2.5 xl:px-3'>
                      <div className='flex items-center justify-start w-[90%]'>
                          <div className={`flex items-center justify-center h-8 w-8 rounded-full border-[3.5px] bg-[#77C214] border-[#CEE9AA] lg:h-9 lg:w-9 xl:h-12 xl:w-12 
                                           xl:border-[5px]`}>
                              <IoCheckmarkDoneOutline className={`text-base text-white lg:text-xl xl:text-2xl`} />
                          </div>
                          <p className='text-[14px] text-[#130F26] font-medium font-sans text-start pl-2.5 w-[80%] xl:text-[17px] xl:font-semibold xl:pl-3'>
                               {item.title}
                          </p>
                      </div>

                      <div className='flex justify-end'>
                          {open.includes(item.id)
                          ?<IoIosArrowUp className={`text-lg text-[#7B8794]`} />
                          :<IoIosArrowDown className={`text-lg text-[#7B8794]`} />
                          }
                      </div>
                  </div>

                  <hr className='border-[1px] border-[#D0D5DD] w-full opacity-50 mt-1.5 xl:mt-2' />

                  {open.includes(item.id) &&
                  <p className='text-[13px] text-[#7B8794] mt-2.5 px-2.5 leading-6 xl:px-4 xl:text-base xl:mt-3.5 xl:leading-[26px]'>
                        {item.desc}
                  </p>
                  }

              </div>
              ))}
         </div>

    </main>
  )
}

export default SupportFaq
