import React, { useEffect } from 'react'
import HeroSection from '../components/homePage/HeroSection'
import Partners from '../components/homePage/Partners'
import HomeSection2 from '../components/homePage/HomeSection2'
import HomeSection3 from '../components/homePage/HomeSection3'
import WhatWeOffer from '../components/homePage/WhatWeOffer'
import HomeSection6 from '../components/homePage/HomeSection6'
import HomeContactUs from '../components/homePage/HomeContactUs'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import { useLocation } from 'react-router-dom'
import MetaTags from '../MetaTags'


const Home = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);


  return (
    <div className='flex flex-col items-center justify-center w-full' >
         <MetaTags
         title="Home page" 
         description="This is Morgen green website" 
         link="/"
          />
         <HeroSection />
         <Partners />
         <HomeSection2 />
         <HomeSection3 />
         <WhatWeOffer />
         <HomeSection6 />
         <HomeContactUs />
         <Subscribe />
         <Footer />
    </div>
  )
}


export default Home
