import React from 'react'
import Navbar from '../general/Navbar';
import { Link } from 'react-router-dom';
import { SlArrowRight } from "react-icons/sl";
import Bg from '../../assets/images/services.png'





const AutobotHero = ({servColor, appDevIcon, appDev}) => {


  return (
    <div className='relative flex flex-col items-center justify-center font-inter w-full h-60 bg-image bg-cover bg-center bg-no-repeat md:h-60 lg:h-[300px] xl:h-[370px]' 
                    style={{backgroundImage: `url(${Bg})`}}>

        <div className='absolute top-0 w-full h-60 bg-gradient-to-r from-[#000000] via-[#000000]-75 to-[#3A3A3A] opacity-60 md:h-60 lg:h-[300px] xl:h-[370px]'></div>

        <Navbar servicesTab='text-[#6FC400]' />

        <div className='flex flex-col items-start justify-start w-full px-5 z-30 mt-24 md:px-8 md:mt-16 lg:px-16 lg:mt-10 xl:px-24 xl:mt-10 max-w-[1440px]'>
             <div className='flex items-center justify-start text-white'>
                 <Link to='/' className='text-[12px] font-inter font-normal text-white md:font-light lg:text-[12px] xl:text-base'>
                      Home
                 </Link>
                 <SlArrowRight className='text-[#6FC400] text-[10px] mx-1 lg:mx-1.5' />
                 <Link className={`text-[12px] text-[#6FC400] font-inter font-normal md:font-light lg:text-[12px] xl:text-base ${servColor}`}>
                      Document
                  </Link>
                  
                 {appDevIcon}
                 <Link to='/document/auto-bot' className='text-[12px] font-inter font-normal text-[#6FC400] md:font-light lg:text-[12px] xl:text-base'>
                      {appDev}
                  </Link>
             </div>


              <p className='text-2xl font-normal text-white mt-3 font-sans md:text-4xl md:mt-7 lg:text-[45px] lg:font-normal lg:mt-11 xl:text-[55px] xl:mt-14'>
                 Auto-bot 2023/2024 Version
              </p>
        </div>
    </div>
  )
}

export default AutobotHero
