import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import ErrorNavbar from '../components/general/ErrorNavbar';
import Footer from '../components/general/Footer';
import image from '../assets/images/errorImg.png'
import { HiArrowLongLeft } from 'react-icons/hi2';



const PageNotFound = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);


  return (
    <div className='flex flex-col items-center justify-center w-full h-full font-inter'>
         <ErrorNavbar />

         <div className='relative flex flex-col items-center justify-start w-full mt-40 mb-28 px-5 md:px-8 md:flex-row md:justify-between lg:px-16 lg:mt-52 lg:mb-52 
                         xl:px-24 xl:mt-72 xl:mb-72 max-w-[1440px]'>
             {/* TEXTS AND BUTTONS */}
             <div className='flex flex-col items-start w-full z-20 md:w-[50%] lg:w-[44%] xl:w-[42%]'>
                 {/* ICON */}
                 <div className='flex items-center justify-start'>
                     <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                     <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
                 </div>

                 {/* DESCRIPTION */}
                 <div className='flex flex-col items-start justify-start md:items-start'>
                     <p className='text-2xl text-[#130F26] font-semibold font-sans text-start w-full pt-4 lg:text-3xl xl:text-4xl xl:pt-5'>
                          OOPS...
                     </p>

                     <p className='text-[15px] text-[#435064] font-medium font-sans text-start w-full pt-3 lg:text-[17px] xl:text-2xl xl:pt-4'>
                          Page not found
                     </p>

                     <p className='text-[12px] text-[#7B8794] font-medium font-inter leading-[26px] pt-3.5 lg:leading-[22px] xl:text-[16px] xl:leading-8 xl:pt-6'>
                          We couldn't find the page you were looking for. It seems the page you're trying to reach doesn't exist or has been moved! We suggest you go back to home.
                     </p>

                     <Link to='/'
                     className='flex items-center justify-center text-white bg-[#77C214] mt-9 h-10 w-[155px] rounded xl:h-12 xl:w-[185px] xl:mt-10'>
                          <HiArrowLongLeft className='text-2xl xl:text-3xl' />
                          <p className='text-[13px] rounded font-medium pl-2 xl:text-[15px]'>
                              Back to home
                          </p>
                     </Link>
                 </div>
             </div>

             {/* MOBILE BACKGROUND IMAGE */}
             <div className='absolute top-0 flex items-center justify-center w-full opacity-10 md:hidden'>
                  <img className='object-cover w-[275px]'
                  src={image} alt='desc-img' loading='lazy' /> 
             </div>

             {/* IMAGE */}
             <div className='hidden md:flex flex-col items-end w-[47%] md:-mt-6 md:opacity-80 lg:opacity-85 lg:w-[43%] xl:w-[58%] xl:opacity-95'>
                  <img className='object-cover w-[350px] lg:w-[400px] xl:w-[550px]'
                  src={image} alt='desc-img' loading='eager' />
             </div>
        </div>


         <Footer />
    </div>
  )
}


export default PageNotFound
