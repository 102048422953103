import React, { useEffect } from 'react'
import Logo from '../../assets/images/logo.png'
import { BiLogoFacebook } from "react-icons/bi";
import { SlSocialInstagram } from "react-icons/sl";
import { IoLogoTwitter } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";



const Footer = () => {

     const socials = [
          {
               id: 1,
               icon: <BiLogoFacebook className='text-white text-xl xl:text-2xl' />,
               duration: "500",
               link: '/',
          },
          {
               id: 2,
               icon: <SlSocialInstagram className='text-white text-sm xl:text-base' />,
               duration: "1000",
               link: '/',
          },
          {
               id: 3,
               icon: <IoLogoTwitter className='text-white text-sm xl:text-base' />,
               duration: "1500",
               link: '/',
          },
          {
               id: 4,
               icon: <FaLinkedinIn className='text-white text-sm xl:text-base' />,
               duration: "2000",
               link: 'https://www.linkedin.com/company/morgen-green/',
          },
     ]


     useEffect(() => {
          AOS.init();
          AOS.refresh();
        }, []);


  return (
    <div className='flex flex-col items-center w-full font-inter bg-[#222222] pt-8 pb-2 lg:pt-14 xl:pt-[75px]'>
         <Link to='/' className='flex items-center justify-center w-full'>
            <img data-aos="fade" data-aos-duration="2000" data-aos-easing="ease-in-out"
             className='w-40 md:w-44 lg:w-48 xl:w-[275px]'
            src={Logo} alt='morger_green_logo' />
         </Link>

         <div className='flex items-center justify-between flex-wrap w-full mt-8 px-5 md:px-8 md:w-[70%] md:mt-10 lg:px-16 lg:w-[60%] xl:mt-[60px] xl:w-[57%]'>
             <Link to='/services' className='text-[11px] text-white font-inter font-medium md:font-normal xl:text-base'>
                 Our Services
             </Link>

             <Link to='/whoWeAre' className='text-[11px] text-white font-inter font-medium md:font-normal xl:text-base'>
                 Who we are
             </Link>

             <Link to='/news-update' className='text-[11px] text-white font-inter font-medium md:font-normal xl:text-base'>
                 News & Updates
             </Link>

             <Link to='/suppportPage' className='hidden md:flex text-[11px] text-white font-inter font-medium md:font-normal xl:text-base'>
                Support
             </Link>

             <p className='hidden md:flex text-[11px] text-white font-inter font-medium md:font-normal xl:text-base'>
                 Privacy Policy
             </p>
         </div>

         <div className='flex items-center justify-center flex-wrap w-full mt-6 md:hidden'>
             <Link to='/suppportPage' className='text-[11px] text-white font-inter font-medium px-5'>
                 Support
             </Link>

             <p className='text-[11px] text-white font-inter font-medium px-5'>
                  Privacy Policy
             </p>
         </div>

         <div className='flex items-center justify-between w-[55%] mt-8 md:hidden'>
              <div className='flex items-center justify-center h-7 w-7 rounded-full bg-[#383838]'>
                   <BiLogoFacebook className='text-white text-xl' />
              </div>

              <div className='flex items-center justify-center h-7 w-7 rounded-full bg-[#383838]'>
                   <SlSocialInstagram className='text-white text-sm' />
              </div>

              <div className='flex items-center justify-center h-7 w-7 rounded-full bg-[#383838]'>
                   <IoLogoTwitter className='text-white text-sm' />
              </div>

              <div className='flex items-center justify-center h-7 w-7 rounded-full bg-[#383838]'>
                   <FaLinkedinIn className='text-white text-sm' />
              </div>
         </div>

         <div className='w-full h-[0.2px] bg-[#7B8794] mt-4 md:mt-8 lg:mt-10 xl:mt-14'></div>

         <div className='flex flex-col items-center w-full mt-3 mb-1 md:flex-row md:justify-between md:px-8 md:mb-3 md:mt-5 lg:px-16 lg:mb-5 xl:px-24 xl:mt-7 xl:mb-7'>
             <p className='text-[9px] font-light font-inter text-[#D0D5DD] md:text-[10px] xl:text-sm'>
                © 2024 Morgen Green Limited. All rights reserved.
             </p>

             <div className='hidden md:flex items-center justify-between mt-8 md:mt-0'>
                 {socials.map((item) => (
                    <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer"
                    className='flex items-center justify-center h-7 w-7 rounded-full bg-[#383838] md:ml-4 xl:h-8 xl:w-8 xl:ml-5'>
                         {item.icon}
                    </a>
                 ))}
             </div>
         </div>

    </div>
  )
}

export default Footer
