import React, { useState } from 'react'
import technology from '../../assets/images/technology.jpg'
import alliances from '../../assets/images/alliances.jpg'
import consultancy from '../../assets/images/consultancy.jpg'
import { motion } from 'framer-motion';



const HomeSection3 = () => {
    const [hover, setHover] = useState(false);

    const choose = [
        {
            id: 1,
            image: technology,
            title: 'Technology',
            desc: 'We are known for building and managing the backbone that powers the era of enterprise hybrid IT.',
        },
        {
            id: 2,
            image: alliances,
            title: 'Strong Alliances',
            desc: 'We have powerful partnerships that provide comprehensive business solutions for you business growth.',
        },
        {
            id: 3,
            image: consultancy,
            title: 'IT Consultancy',
            desc: "We’re transforming enterprises and workforces via enhanced customer experiences, operational and process consulting.",
        },
    ]

    const handleMouseOver = (item) => {
        setHover(item)
    }
    

  return (
    <main className='flex flex-col items-center justify-start w-full font-inter px-5 max-w-[1440px] md:px-0'>
            <div className='flex flex-col items-center justify-center w-full h-[75px] md:h-20 bg-[#ffffff] lg:h-[100px] xl:h-32'>
                 <p className='text-[11px] font-normal text-[#6FC400] tracking-wide md:text-xs lg:text-base'>
                      What is our Speciality?
                 </p>
                 <p className='text-xl font-medium text-[#130F26] tracking-wide mt-1 md:text-2xl lg:text-[25px] lg:mt-[6px]
                               xl:text-3xl xl:tracking-wider'>Why choose us?</p>
            </div>


            {/* MOBILE SCREEN AND TABLET */}
          <div className='flex flex-col items-center justify-start flex-wrap w-full md:mt-7 md:flex-row md:items lg:hidden'>
           {choose.map((item) => {
             return(
           <div key={item.id} className='relative flex flex-col items-center justify-end w-full h-[360px] mt-[18px] z-30 bg-cover bg-center 
                         bg-no-repeat md:h-[275px] md:w-[33.3%]' style={{backgroundImage: `url(${item.image})`}}>

               <div className={`absolute flex flex-col items-center justify-end pb-4 w-full inset-x-0 bottom-0 bg-gradient-to-b from-transparent via-[#221f1f] 
                              to-[#221f1f] h-[175px] md:h-[200px]`}>
                      <div className='flex flex-col items-start'>
                          <p className={`text-xl font-sans font-medium text-start w-full pl-6 tracking-wider md:text-lg bg-gradient-to-t from-[#6FC400] via-[#adf450] to-[#ffffff] 
                                         text-transparent bg-clip-text`}>
                               {item.title}
                          </p>

                          <p className={`text-[13px] w-[87%] leading-[22px] font-inter text-white py-2 px-[5px] pl-6 md:w-full md:leading-5 md:text md:px-6 
                                         md:text-[11px] md:font-light md:pt-3`}>
                               {item.desc}
                          </p>
                      </div>
               </div>

           </div>
               )
           })}

         </div>


       {/* DESKTOP SIZE */}
       <div className='hidden lg:flex flex-col items-center justify-start flex-wrap w-full md:mt-7 md:flex-row lg:mt-9 xl:mt-12 max-w-[1440px]'>
           {choose.map((item) => {
             return(
           <div onMouseOver={()=>handleMouseOver(item.id)} onMouseOut={()=>setHover(false)}
           key={item.id} className='relative flex flex-col items-center justify-end w-full h-[360px] mt-[18px] z-30 bg-cover bg-center 
                         bg-no-repeat md:h-[275px] lg:h-[370px] xl:h-[507px] md:w-[33.3%]' style={{backgroundImage: `url(${item.image})`}}>

             {hover === item.id 
               ?<motion.div 
                className={`absolute flex flex-col items-center justify-end pb-4 w-full inset-x-0 bottom-0 bg-gradient-to-b from-transparent via-[#221f1f] to-[#221f1f] 
                           h-[175px] md:h-[200px] lg:h-[250px] lg:pb-7 xl:h-[350px] xl:pb-12`}>
                    
                     <motion.div 
                     initial={{opacity: 0, scale: 1}}
                     animate={{opacity: [0, 0.3, 0.3, 0.3, 0.3, 0.3, 1], scale: 1, y: ["125%", "0%"]}}
                     transition={{ duration: 0.8, delay: 0.3, easing: "easeInOut", }} 
                     className='flex flex-col items-center'>
                     <p className={`text-xl font-sans text-start w-full pl-6 tracking-wider md:text-xl lg:tracking-wider lg:pl-11 
                                    xl:text-[32px] xl:pl-14 bg-gradient-to-t from-[#6FC400] via-[#adf450] to-[#ffffff] text-transparent bg-clip-text xl:leading-10`}>
                          {item.title}
                     </p>

                     <p className={`text-[12px] leading-5 font-inter text-white text-start py-2 px-[5px] pl-6 md:text md:px-6 
                                    md:text-[11px] md:pt-3 lg:pl-11 lg:text-[13px] lg:pt-4 xl:text-base xl:px-14 xl:pt-[25px]`}>
                          {item.desc}
                     </p>
                     </motion.div>
               </motion.div>

               :<div className='absolute flex flex-col items-start justify-end h-[220px] w-full pb-6 z-30 md:pb-7 xl:pb-11'>
                     <div className='w-[40%] h-[1.5px] bg-[#6FC400] mb-[4px] opacity-100 md:w-[33%] md:mb-2 lg:h-[2px] xl:w-[31.5%] xl:mb-4 xl:h-[3.3px]'></div>
                     
                     <p className={`text-lg font-sans text-center text-white w-full tracking-wide md:text-xl md:text-start 
                  md:pl-6 lg:tracking-wider lg:pl-11 xl:text-[32px] xl:pl-14`}>
                          {item.title}
                     </p>
               </div>
             }

           </div>
               )
           })}

        </div>

    </main>
  )
}

export default HomeSection3
