import React from 'react'
import { IoMailOutline } from 'react-icons/io5'
import { SlLocationPin, SlPhone } from 'react-icons/sl'


const SupportContent = () => {

    const supportDetails = [
        {
            id: 1,
            icon:  <SlPhone className={`text-[27px] text-white xl:text-4xl`} />,
            title: 'Let’s talk on the phone',
            desc: <p>
                        Give us a call at <a href="tel:+234(0) 911 600 6006" className='text-[#77C214]'>0911 600 6006</a> to speak directly with our friendly staff
                  </p>
        },
        {
            id: 1,
            icon:  <IoMailOutline className={`text-[27px] text-white xl:text-4xl`} />,
            title: 'Send us an email',
            desc: <p>
                      you can shoot us an email at <a href="mailto:user@info@morgengreen.com" className='text-[#77C214]'>info@morgengreen.com</a>
                  </p>
        },
        {
            id: 1,
            icon:  <SlLocationPin className={`text-[27px] text-white xl:text-4xl`} />,
            title: 'Visit us at our location',
            desc: <p>
                    Visit our headquarters, <a 
  role="button"
  href={`https://www.google.com/maps/search/?api=1&query=Bayview+estate,+Ikate,+Lagos+state`}
  target="_blank"
  rel="noopener noreferrer"
  className='text-[#77C214]'
  onClick={(e) => {
    window.confirm("Would you like to find us on the map?");
  }}
>
  Bayview estate, Ikate, Lagos state
</a>
                    {/* <span className='text-[#77C214]'>Bayview estate, Ikate, Lagos state.</span> */}
                  </p>
        },
    ]

  return (
    <main className='flex flex-col items-center justify-center font-inter w-full px-5 md:px-8 lg:px-16 xl:px-24 max-w-[1440px]'>
          <div className='flex flex-col items-start w-full'>
              {/* ICON */}
              <div className='flex items-center justify-start'>
                  <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                  <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
              </div>

              {/* DESCRIPTION */}
              <div className='flex flex-col items-center justify-start md:items-start'>
                  <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full pt-2.5 lg:text-[17px] xl:text-2xl'>
                       Get Support & Contact Us
                  </p>

                  <p className='text-[12px] text-[#7B8794] font-normal leading-[26px] pt-3.5 lg:leading-[22px] xl:text-[16px] xl:leading-8 xl:pt-6'>
                        We're Here to Help – Reach Out for Assistance or Inquiries Anytime. We would be delighted to answer any inquiry you might have about your 
                        IT infrastructure and needs, just use the form below or choose one of the alternative methods of communication. We’re available from Monday 
                        to Friday, 08:00-17:00 to take your call.
                  </p>
              </div>
          </div>


          <div className='flex flex-col items-center justify-start w-full mt-4 md:flex-row md:justify-between md:flex-wrap xl:mt-7'>
              {supportDetails.map((item) => (
              <div key={item.id} className='flex flex-col items-center justify-center w-full border-[1px] border-[#D0D5DD] rounded-[10px] px-4 py-7 my-2.5 md:w-[220px]
                                            md:py-0 md:px-2 md:h-[190px] lg:h-[180px] lg:rounded-lg lg:w-[275px] lg:px-5 xl:h-[215px] xl:w-[325px] xl:px-3'>
                  <div className={`flex items-center justify-center h-[60px] w-[60px] rounded-full border-[6px] bg-[#77C214] border-[#CEE9AA] xl:h-[70px] xl:w-[70px]`}>
                      {item.icon}
                  </div>
                  <p className='text-[13px] text-[#130F26] font-medium font-sans py-2 xl:text-base xl:pt-2.5 xl:font-semibold'>
                      {item.title}
                  </p>
                  <p className='text-[11px] text-[#7B8794] font-normal pt-0.5 px-3.5 text-center w-full xl:text-sm xl:pt-0'>
                       {item.desc}
                  </p>
              </div>
              ))}
          </div>

    </main>
  )
}

export default SupportContent
