import React, { useEffect, useState } from 'react'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import { SlArrowRight } from "react-icons/sl";
import { IoWalletOutline } from "react-icons/io5";
import { HiOutlineArrowTrendingUp } from "react-icons/hi2";
import { LuClipboardList } from "react-icons/lu";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation } from 'react-router-dom';
import AutobotHero from '../components/auto-bot/AutobotHero';
import { motion } from 'framer-motion';



const Autobot = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);

  const tabsData = [
    {
      id: 1,
      title: 'Windows',
      activeIcon: <IoWalletOutline className={`text-[16px] text-white`} />,
      inActiveIcon: <IoWalletOutline className={`text-[16px] text-[#D0D5DD]`} />,
      desc: 
      <div className='flex items-start justify-start flex-wrap gap-14 py-10'>
      <a 
      className='text-[#4f692c] underline text-lg font-semibold cursor-pointer'
      href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/candidate-windows.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T123929Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=75e5f62bfb0bf6a3abd8eaeac971aaa86402d7d1f838d62954b46e2b9f642683'
      download
      >
       Candidates
      </a>
      <a 
      className='text-[#4f692c] underline text-lg font-semibold cursor-pointer'
      href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/admin-windows.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124112Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=92af08b5d8a360aba9e2ef0e70d3c9b9cdeb60387267b5f3622b792e665f04e5'
      download
      >
       Admin
      </a>
      <a 
      className='text-[#4f692c] underline text-lg font-semibold cursor-pointer'
      href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/Installation%20Guide.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T134816Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9560a6b259304330289a348c05b308619caedac6673921b63c3105a0c533118a'
      download
      >
       Installation Guide
      </a>
  </div>
    },
    {
      id: 2,
      title: 'Linux',
      activeIcon: <HiOutlineArrowTrendingUp className={`text-[16px] text-white`} />,
      inActiveIcon: <HiOutlineArrowTrendingUp className={`text-[16px] text-[#D0D5DD]`} />,
      desc: 
      <div className='flex items-start justify-start flex-wrap gap-14 py-10'>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/candidate-linux.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124045Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=64be00e6aac820efcf244c2074df7b6000202b06e7da8e04f8cc9630c89d32cd'
                       download
                       >
                        Candidates
                       </a>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/admin-linux.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124136Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=e4f5c56252e353594795770214db79513d29a0554a535d83bf6f7ccf5cd20066'
                       download
                       >
                        Admin
                       </a>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/Installation%20Guide.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T134816Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9560a6b259304330289a348c05b308619caedac6673921b63c3105a0c533118a'
                       download
                       >
                        Installation Guide
                       </a>
        </div>
    },
    {
      id: 3,
      title: 'Archives',
      activeIcon: <LuClipboardList className={`text-[16px] text-white`} />,
      inActiveIcon: <LuClipboardList className={`text-[16px] text-[#D0D5DD]`} />,
      desc:
      <div className='flex items-center justify-start flexx-wrap gap-14 py-10'>
                       <a 
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/autobot-archive-22-10-2024-v1.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124219Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=37ed997760476c073ed1ab51863af34f149d9d96b341d37158abc76297b94659'
                       download
                       className='text-[#4f692c] underline text-lg font-semibold'>
                        2023/2024 Auto-bot archives
                       </a>
      </div>
    },
  ]



  const [open, setOpen] = useState([tabsData[0].id]);

  const [tab, setTab] = useState(1);
  
  const handleTab = (item) => {
    setOpen((prevState) =>
      prevState.includes(item)
        ? prevState.filter((tabId) => tabId !== item)
        : [...prevState, item]
    );

  }

  const [enter, setEnter] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
     if(inputValue === 'ebw-mike-olaGreeen'){
        setEnter(true)
     }else if(inputValue !== 'ebw-mike-olaGreeen'){
        setError('wrong password')
     }
  }


  return (
    <div>
    {
    !enter ?
    <main className='flex items-center justify-center h-screen w-full'>

        <div className='flex flex-col items-center justify-center w-[90%] shadow-md shadow-slate-400 p-10 py-14 rounded md:w-[50%] lg:w-[37%] xl:w-[25%]'>
            <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full lg:text-[17px] xl:text-xl'>
                    Password
            </p>
            <input 
            className='border-[1px] border-[#77C214] rounded-lg pl-3 text-xs h-12 w-full mt-2 outline-[#77C214]'
            type="text" 
            label="password"
            value={inputValue}
            onChange={handleInputChange}
            placeholder='Kindly input your password'
            />
            {/* { error !== '' && */}
            <p className='text-[11px] text-red-500 font-medium font-sans text-start w-full mt-5 lg:text-[13px] xl:text-sm'>
                    {(error && inputValue !== "") && error }
            </p>
            {/* } */}
            <motion.div 
                   initial={{scale: 1, backgroundColor: '#6FC400'}}
                   whileHover={{ backgroundColor: '#000000', scale: 1}}
                   whileTap={{ scale: 0.9 }}
                   transition={{ duration: 0.4, easing: "easeIn", }} 
                   onClick={handleSubmit}
                   className='flex items-center justify-center w-full rounded-[4px] h-10 bg-[#6FC400] mt-2.5 cursor-pointer hover:border-[2px] border-[#6FC400] md:w-[300px] lg:w-full lg:h-11  xl:h-12'>
                        <p className='text-xs text-white font-medium xl:text-sm'>
                              Submit
                        </p>
            </motion.div>
        </div>
    </main>
    :
    <main className='flex flex-col items-center justify-center font-inter w-full'>
        <AutobotHero appDev={'Auto-bot'} appDevIcon={<SlArrowRight className={`text-white text-[10px] mx-1 lg:mx-1.5`} />} /> 

        <div className='flex flex-col items-center justify-center w-full mt-8 mb-14 px-5 md:px-8 md:mt-14 md:mb-16 lg:mt-20 lg:mb-20 lg:px-16 xl:mt-28'>
            <div className='flex items-center justify-center'>
                {/* ICON */}
               <div className='flex items-center justify-center'>
                  <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                  <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
               </div>
               <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full pl-2 md:pl-3 lg:text-[17px] xl:text-2xl xl:pl-5'>
                    Auto-bot installation file and guide
               </p>
           </div>

           <p className='text-[12px] text-[#7B8794] font-normal text-center leading-[26px] pt-3 w-full md:pt-5 md:w-[85%] lg:leading-[22px] lg:pt-[22px] lg:w-[75%] 
                         xl:text-[16px] xl:leading-8 xl:pt-7 xl:w-[65%]'>
                         Download links have been provided for each application. Kindly click the link on the Operating System of your choice to download their 
                         respective files. Also a link to download archives from previous year is also provided on the archive tab
           </p>

           {/* MOBILE DROPDOWN */}
           <div className='flex flex-col items-center w-full mt-4 mb-5 md:hidden'>
               {tabsData.map((item) => (
               <div key={item.id} className={`flex flex-col items-center w-full border-[1px] rounded-xl mt-4 py-1.5 ${open.includes(item.id) ? 'border-[#D0D5DD] mb-1' : 'border-none'}`}>
                   <div onClick={()=>handleTab(item.id)} className='flex items-center justify-between w-full px-2.5'>
                       <div className='flex items-center justify-start'>
                           <div className={`flex items-center justify-center h-[29px] w-[29px] rounded-full border-[3px] ${open.includes(item.id) ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                               {
                                open.includes(item.id) 
                                ?item.activeIcon
                                :item.inActiveIcon
                               }
                           </div>
                           <p className={`font-sans pl-2.5 ${open.includes(item.id) ? 'text-[#101828]' : 'text-[#7B8794]'}`}>
                               {item.title}
                           </p>
                       </div>

                       {open.includes(item.id)
                        ?<IoIosArrowUp className='text-[#101828] text-base' />
                        :<IoIosArrowDown className='text-[#7B8794] text-base' />
                       }
                   </div>

                   <hr className='border-[1px] border-[#D0D5DD] w-full mt-[5px] opacity-35' />

                   {open.includes(item.id) &&
                   <p className='text-[12px] text-[#7B8794] font-normal leading-[26px] pt-2.5 px-2.5'>
                        {item.desc}
                   </p>
                   }
               </div>
              ))}
           </div>


           {/* TABLET SIZE>>> DROPDOWN */}
           <div className='hidden md:flex flex-col items-center w-[85%] mt-10 mb-5 border-[1px] border-[#D0D5DD] border-x-[#77C214] border-b-[#77C214] border-t-[#77C214] rounded-2xl lg:w-[75%] xl:w-[65%] xl:mt-14 xl:border-[1.6px]'>
              {/* TABS */}
              <div className='flex items-center justify-center w-full'>
                  <div onClick={()=>setTab(1)}
                  className={`flex flex-col items-center w-[33.5%] h-[85px] py-3.5 rounded-tl-2xl xl:h-28 xl:py-4 ${tab === 1 ? 'bg-white xl:border-r-[1.5px] xl:border-r-[#77C214]' 
                              : tab === 2 ? 'bg-[#F0F0F0] border-b-[#77C214] border-[2px] border-t-[#D0D5DD] -mt-0.5 -ml-0.5 xl:border-[2.5px] xl:-mt-1' 
                              : 'bg-[#F0F0F0] border-b-[#77C214] border-[1px] border-t-[#D0D5DD] border-t-[2px] -mt-[1px] border-l-[#D0D5DD] border-l-2 -ml-[3px] xl:border-b-2'}`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 1 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <IoWalletOutline className={`text-[16px] xl:text-2xl ${tab === 1 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal pt-0.5 font-sans xl:text-base xl:font-medium xl:pt-1.5 ${tab === 1 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                        Windows
                     </p>
                  </div>


                  <div onClick={()=>setTab(2)}
                  className={`flex flex-col items-center w-[33.35%] h-[85px] py-3.5 border-[2px] border-[#D0D5DD] -mt-[0.8px] border-l-[1px] border-b-[1px] border-b-[#77C214] 
                              border-r-[0.2px] xl:h-28 xl:py-4 ${tab === 1 ? 'border-l-[#77C214] bg-[#F0F0F0] -mt-0.5 xl:border-b-2' 
                              : tab === 2 ? 'border-b-white border-x-[#77C214] border-t-[#77C214] border-t-[1px] bg-white xl:border-x-2' 
                              : 'border-r-[#77C214] bg-[#F0F0F0] xl:border-b-2 xl:border-r-2 xl:-mt-0.5'}`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 2 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <HiOutlineArrowTrendingUp className={`text-[16px] xl:text-2xl ${tab === 2 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal pt-0.5 font-sans xl:text-base xl:font-medium xl:pt-1.5 ${tab === 2 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                         Linux
                     </p>
                  </div>


                  <div onClick={()=>setTab(3)}
                  className={`flex flex-col items-center w-[33.5%] h-[85px] py-3.5 border-[2px] border-[#D0D5DD] -mt-[0.8px] rounded-tr-2xl -mr-[1.5px] border-b-[#77C214] border-b-[1px] border-l-[0.2px] xl:h-28 xl:py-4
                            ${tab === 1 ? 'bg-[#F0F0F0] -mt-0.5 xl:border-b-2 xl:border-t-2 xl:border-r-2' 
                            : tab === 2 ? 'bg-[#F0F0F0] border-t-2 xl:border-b-2 xl:border-t-[2.5px] xl:-mt-0.5' 
                            : 'bg-white border-b-0 border-b-white border-r-0 border-t-[1px] border-t-[#77C214] border-l-[#77C214] border-l-0 xl:border-r-2 xl:border-r-[#77C214]' }`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 3 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <LuClipboardList className={`text-[16px] xl:text-2xl ${tab === 3 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal pt-0.5 font-sans xl:text-base xl:font-medium xl:pt-1.5 ${tab === 3 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                         Archives
                     </p>
                  </div>
              </div>

              <p className='text-[11px] text-[#374151] font-normal text-start leading-6 py-6 w-full px-9 lg:leading-[22px] xl:px-14 xl:py-8 xl:text-[14px] xl:leading-8'>
                  {tab === 1
                   ?
                   <div className='flex items-start justify-start flex-wrap gap-20 py-16'>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer lg:text-xl xl:text-2xl'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/candidate-windows.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T123929Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=75e5f62bfb0bf6a3abd8eaeac971aaa86402d7d1f838d62954b46e2b9f642683'
                       download
                       >
                        Candidates
                       </a>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer lg:text-xl xl:text-2xl'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/admin-windows.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124112Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=92af08b5d8a360aba9e2ef0e70d3c9b9cdeb60387267b5f3622b792e665f04e5'
                       download
                       >
                        Admin
                       </a>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer lg:text-xl xl:text-2xl'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/Installation%20Guide.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T134816Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9560a6b259304330289a348c05b308619caedac6673921b63c3105a0c533118a'
                       download
                       >
                        Installation Guide
                       </a>
                   </div>

                   :tab === 2
                   ?
                   <div className='flex items-start justify-start flex-wrap gap-20 py-16'>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer lg:text-xl xl:text-2xl'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/candidate-linux.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124045Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=64be00e6aac820efcf244c2074df7b6000202b06e7da8e04f8cc9630c89d32cd'
                       download
                       >
                        Candidates
                       </a>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer lg:text-xl xl:text-2xl'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/admin-linux.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124136Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=e4f5c56252e353594795770214db79513d29a0554a535d83bf6f7ccf5cd20066'
                       download
                       >
                        Admin
                       </a>
                       <a 
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer lg:text-xl xl:text-2xl'
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/Installation%20Guide.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T134816Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=9560a6b259304330289a348c05b308619caedac6673921b63c3105a0c533118a'
                       download
                       >
                        Installation Guide
                       </a>
                   </div>

                   :

                   <div className='flex items-center justify-start flexx-wrap gap-3 py-16'>
                       <a 
                       href='https://morgengreen.fra1.digitaloceanspaces.com/autobot/autobot-archive-22-10-2024-v1.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=SRNRB2WOVBJSCJ2HONXR%2F20241104%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20241104T124219Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=37ed997760476c073ed1ab51863af34f149d9d96b341d37158abc76297b94659'
                       download
                       className='text-[#4f692c] underline text-lg font-semibold cursor-pointer lg:text-xl xl:text-2xl'>
                        2023/2024 Auto-bot archives
                       </a>
                   </div>
                  }
              </p>
                
           </div>

        </div>

        <Subscribe />
        <Footer />
    </main>
    }
    </div>
  )
}

export default Autobot
