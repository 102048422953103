import React, { useState } from 'react'
import logo from '../../assets/icons/errorLogo.svg'
import menu from '../../assets/icons/errorMenu.svg'
import close from '../../assets/icons/close.svg'
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { Link, useNavigate } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";
import { CgSearch } from "react-icons/cg";
import { motion } from 'framer-motion';




const ErrorNavbar = ({servicesTab, aboutTab, newsTab, supportTab}) => {
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState(false);

  const [servicesArrow, setServicesArrow] = useState(false);
  const [services, setServices] = useState(false);

  const handleServices = () => {
    setServices(!services)
    setServicesArrow(!servicesArrow)
  }

  const handleServicesBtns = () => {
    setServices(false)
    setServicesArrow(false)
  }

  const navigate = useNavigate();


  return (
    <div className={`${open ? 'fixed bg-[#7c876d33] pt-5 pb-3 top-0 lg:bg-transparent lg:absolute md:top-0 lg:top-5' : 'absolute top-6 md:top-6 lg:top-6 xl:top-8'} 
                     z-[300] flex flex-col items-start w-full font-inter xl:top-3 max-w-[1440px]`}>
          <div className={`flex items-center justify-between w-full px-5 cursor-pointer md:px-8 lg:px-16 xl:px-24`}>
               <Link to='/'>
                   <img className={`w-40 md:w-44 xl:w-52`}
                   src={logo} alt='Morgen_green_logo' />
               </Link>

               <div className='flex items-center justify-end'>
                  <CgSearch onClick={()=>setSearch(!search)} className='text-2xl text-[#130F26] mr-4 md:mr-6 lg:hidden' />
                  <img onClick={()=>setOpen(true)} className='w-7 lg:hidden' src={menu} alt='menu' />
               </div>


               {/*  WEB / DESKTOP */}
               <div className='hidden lg:flex items-start justify-start text-[#130F26] w-[61.5%] xl:w-[55%]'>
                   <div className='flex items-center justify-between w-full'>
                        <div className='relative flex flex-col'>
                            <div className='flex items-center justify-center cursor-pointer'>
                                <Link to='/services' onMouseOver={handleServices}
                                className={`text-xs font-normal mr-2 xl:text-[15px] xl:mr-3 ${servicesTab}`}>
                                   Our Services
                                </Link>

                                {services
                                  ?<SlArrowUp className='text-[10px] text-[#130F26] xl:text-xs' />
                                  :<SlArrowDown className='text-[10px] text-[#130F26] xl:text-xs' />
                                }
                            </div>

                            {
                            services &&
                            <motion.div 
                            initial={{ opacity: 1, scale: 1 }}
                            animate={{opacity: [0, 0, 0.1, 0.1, 0.3, 0.3, 1], scale: 1, y: ["30%", "0%"] }}
                            transition={{duration: 0.7, delay: 0.2,}}
                            className='absolute top-8 flex flex-col items-start justify-center w-52 rounded-xl rounded-tl-none bg-white border-[1px] border-[#374151] text-[#374151] pt-2 px-2 pb-2 xl:w-[285px] xl:top-9'>
                                 <Link to='/appDev' onClick={handleServicesBtns}
                                 className={`flex items-center justify-start w-full text-[10px] font-normal h-9 px-[10px] rounded-md bg-[#ffffff] hover:bg-[#6FC400] hover:text-white 
                                             xl:text-[14px] xl:px-[15px] xl:h-12 xl:mb-2`}>   
                                             Enterprise Application Development                          
                                 </Link>

                                 <Link to='/telecomServices' onClick={handleServicesBtns}
                                 className={`flex items-center justify-start w-full text-[10px] font-normal h-9 px-[10px] rounded-md bg-[#ffffff] hover:bg-[#6FC400] hover:text-white 
                                             xl:text-[14px] xl:px-[15px] xl:h-12 xl:mb-2`}>   
                                             Telecommunication Services                          
                                 </Link>

                                 <Link to='/netServices' onClick={handleServicesBtns}
                                 className={`flex items-center justify-start w-full text-[10px] font-normal h-9 px-[10px] rounded-md bg-[#ffffff] hover:bg-[#6FC400] hover:text-white 
                                             xl:text-[14px] xl:px-[15px] xl:h-12 xl:mb-2`}>    
                                             Network Services                         
                                 </Link>

                                 <Link to='/support' onClick={handleServicesBtns}
                                 className={`flex items-center justify-start w-full text-[10px] font-normal h-9 px-[10px] rounded-md bg-[#ffffff] hover:bg-[#6FC400] hover:text-white 
                                             xl:text-[14px] xl:px-[15px] xl:h-12 xl:mb-2`}> 
                                             Managed IT Services                            
                                 </Link>
                            </motion.div>
                            }
                          

                        </div>

    
                        <Link to='/whoWeAre' className={`text-xs font-normal mr-2 xl:text-[15px] ${aboutTab}`}>
                              Who we are
                        </Link>

                        <Link to='/news-update' className={`text-xs font-normal mr-2 xl:text-[15px] ${newsTab}`}>
                              News & Updates 
                        </Link>

                        <Link to='/suppportPage' className={`text-xs font-normal xl:text-[15px] ${supportTab}`}>
                              Support
                        </Link>


                        <CgSearch onClick={()=>setSearch(!search)} className='hidden lg:flex text-xl text-[#130F26] mr-1 hover:text-[#6FC400] xl:text-2xl' />


                        <motion.div onClick={()=>navigate({pathname: '/suppportPage', search: '?sectionId=LearnMore'})}
                        initial={{scale: 1, backgroundColor: '#6FC400'}}
                        whileHover={{ backgroundColor: '#000000', scale: 1}}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.4, easing: "easeIn", }} 
                        className='flex items-center justify-center w-24 h-8 rounded-[4px] bg-[#6FC400] text-[10px] text-white pt-[3px] hover:bg-black hover:border-[1.5px] border-[#6FC400] 
                                   xl:text-[13px] xl:w-32 xl:h-11'>
                                  Get in Touch
                        </motion.div>
                   </div>
               </div>
          </div>



            {/*  MOBILE / TABLET */}
          {open &&
          <div className='flex w-full'>

          <div onClick={()=>setOpen(false)} className='fixed top-0 w-full h-full bg-[#7a7979] opacity-15'></div>

          <motion.div
          initial={{ opacity: 1 }}
          animate={{opacity: 1, x: ["-100%", "0%"] }}
          transition={{duration: 0.8, delay: 0.2,}} 
          className='fixed top-0 bottom-0 flex flex-col items-center justify-end pt-5 px-7 w-[80%] h-full z-50 bg-[#130F26] md:w-[40%] lg:hidden'>
              <div className='flex items-center justify-between w-full'>
                  <Link to='/' className='z-[100]'>
                      <img className={`w-40 md:w-44 xl:w-52`}
                      src={logo} alt='Morgen_green_logo' />
                  </Link>

                  <img onClick={()=>setOpen(false)} className='w-4 z-[100] lg:hidden' src={close} alt='menu' />
              </div>

              <div className='flex flex-col items-start justify-start w-full h-full text-[#ffffff] mt-14'>
                    <motion.div onClick={()=>navigate({pathname: '/suppportPage', search: '?sectionId=LearnMore'})}
                    initial={{scale: 1, backgroundColor: '#6FC400'}}
                    whileHover={{ backgroundColor: '#000000', scale: 1}}
                    whileTap={{ scale: 0.9 }}
                    transition={{ duration: 0.4, easing: "easeIn", }} 
                    className='flex items-center justify-center w-full h-14 rounded-[4px] bg-[#6FC400] text-sm text-white mb-10 hover:bg-black hover:border-[1.5px] border-[#6FC400]'>
                       Get in Touch
                    </motion.div>

                    <Link to='/services' className={`text-sm font-normal ml-1.5 mb-10 ${servicesTab}`}>
                       Our Services
                    </Link>

                    <Link to='/whoWeAre' className={`text-sm font-normal ml-1.5 mb-10 ${aboutTab}`}>
                         Who we are
                   </Link>

                   <Link to='/news-update' className={`text-sm  font-normal ml-1.5 mb-10 ${newsTab}`}>
                         News & Updates
                   </Link>

                   <Link to='/suppportPage' className={`text-sm font-normal ml-1.5 ${supportTab}`}>
                         Support
                   </Link>

              </div>
          </motion.div>
          </div>
          }


          {search &&
          <motion.div 
          initial={{ opacity: 0, scale: 1 }}
          animate={{opacity: [0, 0.3, 0.3, 0.3, 0.3, 0.3, 1], scale: 1, y: ["-100%", "0%"] }}
          transition={{duration: 0.6, delay: 0.1,}}
          className='flex items-center justify-center w-full px-5 md:justify-end md:px-8 lg:px-16 xl:px-24'>
               <div className='relative flex items-center w-full mt-6 md:w-[55%] xl:mt-10 xl:w-[45%]'>
                 <input className='bg-[#130F26]/10 border-b-[1px] border-[#6FC400]/50 rounded-t-sm backdrop-blur-md placeholder:text-[#130F26]/50 text-[#130F26] 
                                     w-full h-9 outline-none px-4 text-[10px] italic md:h-10 md:text-[11px] xl:h-12 xl:border-b-[2px] xl:px-5 xl:text-xs' 
                 placeholder='search anything...'
                 />

                 <div onClick={()=>setSearch(false)} className='absolute top-1 right-1 flex items-center justify-center w-3.5 h-3.5 rounded-full bg-[#FFFFFF] 
                                                                xl:w-[18px] xl:h-[18px]'>
                     <IoIosClose className='text-sm xl:text-xl' />
                 </div>
               </div>
          </motion.div>
          }


    </div>
  )
}


export default ErrorNavbar
