import React, { useEffect } from 'react'
import image from '../../assets/images/image1.png'
import pattern from '../../assets/images/bgPattern1.svg'
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";



const HomeSection2 = () => {

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className='relative flex flex-col items-center justify-center w-full font-sans bg-gradient-to-r from-[#130F26] to-[#426129] pt-16
                    md:flex-row md:pt-14 lg:pt-[60px] xl:pt-[75px]'>

        <div className='flex flex-col items-center justify-start w-full pl-5 md:flex-row md:pl-8 lg:pl-16 xl:pl-24 max-w-[1440px]'>
            <div className='absolute top-0 right-0 h-[300px] w-[62%] bg-left-bottom md:ml-72 lg:ml-[425px] lg:w-[50%] xl:ml-[42rem]' style={{backgroundImage: `url(${pattern})`}}></div>

           <div className='flex flex-col items-start justify-start w-full z-30 md:w-[45%] md:mb-8 xl:mb-[75px]'>
              <p className='text-[22px] text-white font-normal leading-7 tracking-wider w-[80%] md:w-[90%] lg:text-[26px]
                            lg:leading-9 lg:w-[80%] xl:text-[38px] xl:w-[85%] xl:leading-[55px]'>
                   For expert IT & Telecoms advice you can trust Morgen Green 
              </p>

              <motion.div onClick={()=>navigate({ pathname: '/suppportPage', search: '?sectionId=LearnMore' })}
              initial={{scale: 1, backgroundColor: '#6FC400'}}
              whileHover={{ backgroundColor: '#000000'}}
              whileTap={{ scale: 0.9 }}
              className='flex items-center justify-center w-[125px] font-inter h-10 rounded-[4px] mt-6 cursor-pointer bg-[#6FC400] hover:border-[1.5px] border-[#6FC400] z-20 lg:h-11 xl:w-[150px] xl:h-12'>
                       <p className='text-[11px] text-white font-medium md:font-normal xl:text-[13px]'>Get in touch</p>
              </motion.div>
           </div>


           <div data-aos="fade" data-aos-duration="1800" data-aos-easing="ease-in-out"
           className='flex items-end justify-end w-full mt-12 z-30 md:w-[55%] md:mt-0'>
                <img className='w-full md:w-[500px] lg:w-full'
                src={image} alt='random_image' loading='lazy' />
           </div>

           </div>

    </div>
  )
}

export default HomeSection2
