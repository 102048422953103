import React from 'react'
import hero from '../../assets/images/hero-image.jpg'
import { GoArrowUpRight } from "react-icons/go";
import Navbar from '../general/Navbar';
import { motion } from 'framer-motion';
import TypingEffect from './TypingEffect';
import { Link, useNavigate } from 'react-router-dom';



const HeroSection = () => {

  const navigate = useNavigate();


  return (
    <div className='relative flex flex-col items-center justify-center w-full h-[90vh] bg-image bg-cover bg-center bg-no-repeat md:h-screen'>
        <img className='absolute top-0 h-[90vh] w-full object-cover md:h-screen'
        src={hero} loading='lazy' alt='' />
        <div className='absolute top-0 h-[90vh] w-full bg-gradient-to-r from-[#000000] via-[#000000]-75 to-[#3A3A3A] opacity-60 md:h-screen'></div>

        <Navbar />

        <div className='flex flex-col items-start justify-start w-full px-5 z-30 mt-44 md:px-8 lg:px-16 lg:mt-48 xl:px-24 max-w-[1440px]'>
              <p className='text-3xl font-sans font-medium text-white md:text-4xl lg:text-[45px] lg:font-normal xl:text-[55px]'>
                 The #1 <span className='pl-1'>IT Service</span>
              </p>
              <p className='flex text-3xl font-inter font-medium text-white pt-[6px] md:text-4xl lg:text-[45px] lg:font-normal lg:pt-4 lg:items-center xl:text-[55px] xl:pt-8'>
                  Provider in   <TypingEffect typingSpeed={300} />
              </p>

              <p className='text-xs font-inter font-normal text-white mt-4 w-[90%] leading-5 md:w-[60%] md:font-light md:mt-5 lg:mt-6 lg:w-[41.5%] lg:text-[12px] lg:leading-[22px] xl:mt-9 xl:text-base xl:leading-[26px] xl:w-[38%]'>
                   We oversee vital ICT systems and handle offerings for a diverse clientele, including JSE-listed enterprises, public sector entities, parastatals, and small to medium-sized businesses worldwide.
              </p>

              <div className='flex items-center justify-start w-full mt-5 cursor-pointer md:mt-6 lg:mt-7 xl:mt-9'>
                   <motion.div onClick={()=>navigate({ pathname: '/suppportPage', search: '?sectionId=LearnMore' })}
                   initial={{scale: 1, backgroundColor: '#6FC400'}}
                   whileHover={{ backgroundColor: '#000000', scale: 1}}
                   whileTap={{ scale: 0.9 }}
                   transition={{ duration: 0.4, easing: "easeIn", }}
                   className='flex items-center justify-center w-[125px] h-10 rounded-[4px] bg-[#6FC400] hover:bg-black hover:border-[1.5px] border-[#6FC400] lg:h-11 
                                   xl:w-[150px] xl:h-12'>
                       <p className='text-[11px] text-white font-inter font-medium md:font-normal xl:text-[13px]'>Get in touch</p>
                   </motion.div>

                   <Link to='/whoWeAre' className='flex items-center justify-center w-[105px] rounded-sm bg-transparent ml-2 text-white hover:text-[#6FC400] md:ml-3 xl:ml-5'>
                       <p className='text-[11px] font-inter font-normal xl:text-[13px]'>Learn more</p>
                       <GoArrowUpRight className='text-sm ml-2 xl:text-base' />
                   </Link>
              </div>
        </div>
    </div>
  )
}

export default HeroSection
