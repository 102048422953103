import React from 'react'
import Home from './pages/Home'
import './index.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Services from './pages/Services'
import AppDev from './pages/AppDev'
import Support from './pages/Support'
import NetServices from './pages/NetServices'
import TelecomServices from './pages/TelecomServices'
import WhoWeAre from './pages/WhoWeAre'
import NewsnUpdate from './pages/NewsnUpdate'
import NewsDetailsPage from './pages/NewsDetailsPage'
import SuppportPage from './pages/SuppportPage'
import PageNotFound from './pages/PageNotFound'
import Autobot from './pages/Autobot'



const App = () => {
  
  return (
    <BrowserRouter>
        <Routes>
           <Route path='/' element={<Home />} />
           <Route path='/services' element={<Services />} />
           <Route path='/appDev' element={<AppDev />} />
           <Route path='/support' element={<Support />} />
           <Route path='/netServices' element={<NetServices />} />
           <Route path='/telecomServices' element={<TelecomServices />} />
           <Route path='/whoWeAre' element={<WhoWeAre />} />
           <Route path='/news-update' element={<NewsnUpdate />} />
           <Route path='/newsDetailsPage' element={<NewsDetailsPage />} />
           <Route path='/suppportPage' element={<SuppportPage />} />
           <Route path='/document/auto-bot' element={<Autobot />} />
           <Route path="*" Component={PageNotFound} />
        </Routes>
    </BrowserRouter>
  )
}

export default App

