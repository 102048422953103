import React, { useEffect, useState } from 'react'
import ServicesHero from '../components/servicesPage/ServicesHero'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import { SlArrowRight } from "react-icons/sl";
import TabsContent from '../components/servicesPage/TabsContent';
import TellImg from '../assets/images/telImg.png'
import { PiIntersectThreeLight } from "react-icons/pi";
import { IoRocketOutline } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation } from 'react-router-dom';




const TelecomServices = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);

  const tabsData = [
    {
      id: 1,
      title: 'Your Benefits',
      activeIcon: <PiIntersectThreeLight className={`text-[22px] text-white`} />,
      inActiveIcon: <PiIntersectThreeLight className={`text-[22px] text-[#D0D5DD]`} />,
      desc: <p className='text-start w-full'>
              1. Data Networks 2G, 3G, 4G and GSM-R Networking 
              <br />
              2. Telecommunication Security and Energy
              <br />
              3. eLTE Broadband Access and Trunking Systems
              <br />
              4. Broadband Services and Transmission Deployment
              <br />
              5. Quality of Service Assurance and Video Surveillance
            </p>
    },
    {
      id: 2,
      title: 'Our Offerings',
      activeIcon: <IoRocketOutline className={`text-[16px] text-white`} />,
      inActiveIcon: <IoRocketOutline className={`text-[16px] text-[#D0D5DD]`} />,
      desc: <p className='text-start w-full'>
              1. Wide Coverage: Seamless connectivity across various network generations (2G, 3G, 4G, GSM-R).
              <br />
              2. Enhanced Mobility: Reliable network access on the go.
              <br />
              3. High Speed: Faster data transmission and internet access.
              <br />
              4. Network Interoperability: Smooth communication between different network standards.
              <br />
              5. Robust Security: Protects against cyber threats and unauthorized access.
            </p>
    },
  ]



  const [open, setOpen] = useState([tabsData[0].id]);

  const [tab, setTab] = useState(1);
  
  const handleTab = (item) => {
    setOpen((prevState) =>
      prevState.includes(item)
        ? prevState.filter((tabId) => tabId !== item)
        : [...prevState, item]
    );

  }
  return (
    <main className='flex flex-col items-center justify-center font-inter w-full'>
        <ServicesHero appDev={'Telecommunication Services'} appDevIcon={<SlArrowRight className={`text-white text-[10px] mx-1 lg:mx-1.5`} />} /> 
        <TabsContent 
        telColor='text-[#77C214]' 
        title='Telecommunication Services' 
        desc={`Morgen Green’s global network is built on several years of expertise in the telecommunication services industry and is dedicated to delivering the 
            latest technological advances to consumers around the world. Our Telecommunication Services are certified with the main telephony equipment companies.
             Our proximity to the market leaders enables us to offer you professional products and all the proximity services ranging from advice, integration and
              maintenance guaranteeing the proper functioning and scalability of your equipment.`}
        image={TellImg}
        />


        <div className='flex flex-col items-center justify-center w-full mt-8 mb-14 px-5 md:px-8 md:mt-14 md:mb-16 lg:mt-20 lg:mb-20 lg:px-16 xl:mt-28'>
            <div className='flex items-center justify-center'>
                {/* ICON */}
               <div className='flex items-center justify-center'>
                  <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                  <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
               </div>
               <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full pl-2 md:pl-3 lg:text-[17px] xl:text-2xl xl:pl-5'>
                    Here is what we do for you
               </p>
           </div>

           <p className='text-[12px] text-[#7B8794] font-normal text-center leading-[26px] pt-3 w-full md:pt-5 md:w-[85%] lg:leading-[22px] lg:pt-[22px] lg:w-[75%] 
                         xl:text-[16px] xl:leading-8 xl:pt-7 xl:w-[65%]'>
                         When moving to a new communications provider, it is important to ensure they provide a good balance between service and savings. We undertake thorough 
                         audits of all aspects of our clients’ telecom costs, whilst retaining the highest levels of customer service. 
           </p>

           {/* MOBILE DROPDOWN */}
           <div className='flex flex-col items-center w-full mt-4 mb-5 md:hidden'>
               {tabsData.map((item) => (
               <div key={item.id} className={`flex flex-col items-center w-full border-[1px] rounded-xl mt-4 py-1.5 ${open.includes(item.id) ? 'border-[#D0D5DD] mb-1' : 'border-none'}`}>
                   <div onClick={()=>handleTab(item.id)} className='flex items-center justify-between w-full px-2.5'>
                       <div className='flex items-center justify-start'>
                           <div className={`flex items-center justify-center h-[29px] w-[29px] rounded-full border-[3px] ${open.includes(item.id) ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                               {
                                open.includes(item.id) 
                                ?item.activeIcon
                                :item.inActiveIcon
                               }
                           </div>
                           <p className={`text-sm font-medium font-sans pl-2.5 ${open.includes(item.id) ? 'text-[#101828]' : 'text-[#7B8794]'}`}>
                               {item.title}
                           </p>
                       </div>

                       {open.includes(item.id)
                        ?<IoIosArrowUp className='text-[#101828] text-base' />
                        :<IoIosArrowDown className='text-[#7B8794] text-base' />
                       }
                   </div>

                   <hr className='border-[1px] border-[#D0D5DD] w-full mt-[5px] opacity-35' />

                   {open.includes(item.id) &&
                   <p className='text-[12px] text-[#7B8794] font-normal text-start w-full leading-[26px] pt-2.5 px-2.5'>
                        {item.desc}
                   </p>
                   }
               </div>
              ))}
           </div>


           {/* TABLET SIZE>>> DROPDOWN */}
           <div className='hidden md:flex flex-col items-center w-[85%] mt-10 mb-5 border-[1px] border-[#D0D5DD] border-x-[#77C214] border-b-[#77C214] border-t-[#77C214] rounded-2xl lg:w-[75%] xl:w-[65%] xl:mt-14 xl:border-[1.6px]'>
              {/* TABS */}
              <div className='flex items-center justify-center w-full'>
                  <div onClick={()=>setTab(1)}
                  className={`flex flex-col items-center w-[33.5%] h-[85px] py-3.5 rounded-tl-2xl xl:h-28 xl:py-4 ${tab === 1 ? 'bg-white xl:border-r-[1.5px] xl:border-r-[#77C214]' 
                              : tab === 2 && 'bg-[#F0F0F0] border-b-[#77C214] border-[2px] border-t-[#D0D5DD] -mt-0.5 -ml-0.5 xl:border-[2.5px] xl:-mt-1'}`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 1 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <PiIntersectThreeLight className={`text-[22px] xl:text-3xl ${tab === 1 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal font-sans pt-0.5 xl:text-base xl:font-medium xl:pt-1.5 ${tab === 1 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                         Your Benefits
                     </p>
                  </div>


                  <div onClick={()=>setTab(2)}
                  className={`flex flex-col items-center w-[33.35%] h-[85px] py-3.5 border-[2px] border-[#D0D5DD] -mt-[0.8px] border-l-[1px] border-b-[1px] border-b-[#77C214] 
                              border-r-[0.2px] xl:h-28 xl:py-4 ${tab === 1 ? 'border-l-[#77C214] bg-[#F0F0F0] -mt-0.5 xl:border-b-2' 
                              : tab === 2 && 'border-b-white border-l-[#77C214] border-t-[#77C214] border-t-[1px] bg-white border-r-[#ffffff] xl:border-l-2'}`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 2 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <IoRocketOutline className={`text-[16px] xl:text-2xl ${tab === 2 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal font-sans pt-0.5 xl:text-base xl:font-medium xl:pt-1.5 ${tab === 2 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                         Our Offerings
                     </p>
                  </div>


                  <div className={`w-[33.5%] h-[85px] py-3.5 border-[1px] -mt-[0.8px] rounded-tr-2xl -mr-[1.5px] border-t-[#77C214] border-t-[1px] border-r-[#77C214] border-b-0 border-l-0 xl:h-28 xl:py-4`}>
                  </div>

              </div>

              {tab === 1 ?
              <p className='text-[11px] text-[#374151] font-normal text-start leading-6 py-6 w-full px-9 lg:leading-[22px] xl:px-14 xl:py-8 xl:text-[14px] xl:leading-8'>
                  1. Data Networks 2G, 3G, 4G and GSM-R Networking 
                  <br />
                  2. Telecommunication Security and Energy
                  <br />
                  3. eLTE Broadband Access and Trunking Systems
                  <br />
                  4. Broadband Services and Transmission Deployment
                  <br />
                  5. Quality of Service Assurance and Video Surveillance
              </p>

              :tab === 2 &&

              <p className='text-[11px] text-[#374151] font-normal text-start leading-6 py-6 w-full px-9 lg:leading-[22px] xl:px-14 xl:py-8 xl:text-[14px] xl:leading-8'>
                   1. Wide Coverage: Seamless connectivity across various network generations (2G, 3G, 4G, GSM-R).
                   <br />
                   2. Enhanced Mobility: Reliable network access on the go.
                   <br />
                   3. High Speed: Faster data transmission and internet access.
                   <br />
                   4. Network Interoperability: Smooth communication between different network standards.
                   <br />
                   5. Robust Security: Protects against cyber threats and unauthorized access.
              </p>
              }
                
           </div>

        </div>


        <Subscribe />
        <Footer />
    </main>
  )
}

export default TelecomServices
