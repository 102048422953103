import React, { useEffect } from 'react'
import NewsHero from '../components/newsNupdate/NewsHero'
import SearchBar from '../components/general/SearchBar'
import BrowseNews from '../components/newsNupdate/BrowseNews'
import Comments from '../components/newsDetails/Comments'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import NewsDetails from '../components/newsDetails/NewsDetails'
import { useLocation } from 'react-router-dom'


const NewsDetailsPage = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);

  return (
    <main className='flex flex-col items-center justify-center w-full font-inter'>
         <NewsHero aboutTab='text-[#6FC400]' />
         <SearchBar />
         <NewsDetails />
         <BrowseNews title="Related News and updates" />
         <Comments />
         <Subscribe />
         <Footer />
    </main>
  )
}

export default NewsDetailsPage
