import React from 'react'
import { PiSuitcaseSimple } from "react-icons/pi";
import { IoBonfireOutline } from "react-icons/io5";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { BsHandThumbsUp } from "react-icons/bs";



const AboutContent3 = () => {

    const cards = [
        {
            id: 1,
            title: 'Accountability',
            icon: <PiSuitcaseSimple className={`text-[18px] xl:text-[26px] text-[#ffffff]`} />,
            desc: `We are willing to take responsibility for all our actions, inactions and outcomes. We strive to honor all obligations, agreements and requirements 
                   of our customers.  We recognize that our outcomes affect the performance and commitments of our customers and stakeholders.`,
        },
        {
            id: 2,
            title: 'Winning spirit',
            icon: <IoBonfireOutline className={`text-[18px] xl:text-[26px] text-[#ffffff]`} />,
            desc: `Be it individually or collectively, we have the will to succeed, win and be the best in whatever we do; irrespective of the competition. We collaborate, 
                   think outside the box and aggressively push ourselves and never give up!`,
        },
        {
            id: 3,
            title: 'Collaboration',
            icon: <HiOutlineUserGroup className={`text-[18px] xl:text-[26px] text-[#ffffff]`} />,
            desc: `A great way to meet goals and beat deadlines is when we work seamlessly within ourselves, with our partners and with key stakeholders.  We maintain good 
                   relationships by working seamlessly, sharing knowledge and learning together.`,
        },
        {
            id: 4,
            title: 'Sustainability',
            icon: <BsHandThumbsUp className={`text-[18px] xl:text-[26px] text-[#ffffff]`} />,
            desc: `Be it individually or collectively, we have the will to succeed, win and be the best in whatever we do; irrespective of the competition. We collaborate, 
                   think outside the box and aggressively push ourselves and never give up!`,
        },
    ]


  return (
    <main className='flex flex-col items-center justify-center w-full font-sans px-5 mt-9 mb-14 max-w-[1440px] md:px-8 md:mb-14 lg:mt-6 lg:px-16 xl:px-24 xl:mt-3 xl:mb-16'>
           <div className='flex flex-col items-start justify-center w-full'>
                {/* ICON */}
               <div className='flex items-center justify-center'>
                  <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                  <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
               </div>
               <p className='text-[15px] text-[#130F26] font-medium text-start w-full mt-2 lg:text-[17px] lg:mt-3 xl:text-2xl xl:mt-4'>
                     Our Core Values
               </p>
            </div>

           <p className='text-[12px] text-[#7B8794] font-normal font-inter text-start leading-[23px] pt-2.5 w-full md:pt-2.5 lg:leading-[22px] lg:pt-2 xl:text-[16px] 
                         xl:leading-8 xl:pt-3.5'>
                         The Guiding Principles that Define Our Commitment and helps us at Morgen Green to reach Excellence, Innovation, and Customer Success
           </p>



           <div className='flex flex-col items-center justify-start w-full mt-8 md:flex-row md:flex-wrap md:justify-between lg:mt-9 xl:mt-12'>
               {cards.map((item) => (
               <div key={item.id} className='flex flex-col items-start w-full border-[1px] border-[#D0D5DD] rounded-3xl px-3.5 py-5 mb-5 md:w-[47.5%] md:h-[215px] md:mb-8 
                                             lg:w-[48%] lg:h-[190px] lg:px-5 xl:px-7 xl:py-7 xl:h-[245px] xl:mb-12'>
                   <div className='flex items-center justify-start w-full px-3.5 md:px-0'>
                        <div className={`flex items-center justify-center h-[31px] w-[31px] rounded-full border-[3px] bg-[#77C214] border-[#CEE9AA] xl:h-[45px] xl:w-[45px]`}>
                          {item.icon}
                       </div>
                       <p className={`text-[15px] font-normal text-[#130F26] pl-2.5 xl:text-lg xl:font-medium xl:pl-3`}>
                           {item.title}
                       </p>
                   </div>

                   <p className='text-[12px] text-[#7B8794] font-normal text-start leading-[22px] pt-2.5 w-full md:pt-2.5 lg:leading-[22px] lg:pt-2 xl:text-[16px] 
                         xl:leading-8 xl:pt-3.5'>
                        {item.desc}
                   </p>
               </div>
            ))}

           </div>

    </main>
  )
}

export default AboutContent3
