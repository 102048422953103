import React, { useEffect } from 'react'
import HomeContactUs from '../homePage/HomeContactUs'
import { useLocation } from 'react-router-dom';

const SupportContact = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sectionId]);

  return (
    <main className='flex flex-col items-start w-full mt-5 max-w-[1440px]'>
         {/* ICON */}
         <div className='flex items-center justify-start px-5 md:px-8 lg:px-16 xl:px-24'>
             <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
             <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
         </div>

         {/* DESCRIPTION */}
         <div className='flex flex-col items-center justify-start px-5 md:px-8 md:items-start lg:px-16 xl:px-24'>
             <p className='text-[15px] text-[#130F26] font-medium text-start w-full pt-2.5 lg:text-[17px] xl:text-2xl'>
                  Contact Us
             </p>

             <p className='text-[12px] text-[#7B8794] font-normal leading-[26px] w-full pt-3.5 lg:leading-[22px] xl:text-[16px] xl:leading-8 xl:pt-6 xl:w-[90%]'>
                  Have questions or need assistance? Fill out the form below, and our team will get back to you as soon as possible. We’re here to help with any 
                  inquiries you may have
             </p>
         </div>

         <div id='LearnMore' className='w-full -mt-2.5 md:mt-0 lg:mt-9'>
             <HomeContactUs />
         </div>
    </main>
  )
}

export default SupportContact
