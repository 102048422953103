import React, { useEffect } from 'react'
import image from '../../assets/images/callBackImg.png'
import { motion } from 'framer-motion';
import AOS from "aos";
import "aos/dist/aos.css";



const HomeContactUs = () => {

     useEffect(() => {
          AOS.init();
          AOS.refresh();
        }, []);

  return (
    <div className='flex flex-col items-start w-full font-inter lg:flex-row lg:items-end lg:justify-between max-w-[1440px]'>

        {/* IMAGE */}
         <div className='hidden lg:flex justify-start items-start w-[50%]'>
            <img  data-aos="fade" data-aos-duration="2000" data-aos-easing="ease-in-out"
            className='object-cover h-[715px] xl:h-[900px]'
            src={image} alt='contactUs_image' />
        </div>



          {/* FORM */}
        <div className='flex flex-col items-start w-full px-5 lg:w-[50%] md:px-8 lg:px-14 xl:px-32'>
             <div className='flex flex-col items-start justify-center w-full pt-9 md:pt-8 lg:pt-6 xl:pt-8'>
                  <p className='text-[11px] font-sans font-normal text-[#6FC400] tracking-wide md:text-xs lg:text-sm'>
                        Want to Speak to us Directly?
                  </p>
                  <p className='text-xl font-sans font-medium text-[#130F26] tracking-wide mt-2 md:mt-3 md:text-xl lg:text-[25px] lg:mt-[6px]
                                xl:text-2xl xl:tracking-wider'>Request a Call Back</p>
                    
                  <p className='text-[12px] text-[#7B8794] font-normal leading-[26px] pt-3.5 md:pt-4 lg:leading-[22px] xl:text-[14px] xl:leading-8 xl:pt-6'>
                         Would you like to speak to one of our technology specialist over the phone? Just submit your details and we'll
                         be in touch shortly.You can also <a href="mailto:user@info@morgengreen.com" className='text-[#6FC400] underline'>send an email</a> if you would prefer.
                  </p>
             </div>

             <div className='flex flex-col items-start w-full mt-4 md:mt-3 xl:mt-4'>
                   <div className='flex flex-col items-center justify-between w-full md:flex-row lg:mt-6'>
                         {/* FULL NAME */}
                         <div className='flex flex-col items-start w-full mt-7 lg:mt-0'>
                             <p className='text-xs text-[#323F4B] font-semibold lg:text-xs xl:text-sm'>
                                  Full name
                             </p>
                             <input className='h-10 rounded-[4px] border-[1px] border-[#D0D5DD] mt-3.5 px-3.5 text-[11px] w-full md:w-[87%] lg:h-9 
                                               lg:w-[90%] lg:mt-2 xl:h-11 xl:text-sm' 
                              placeholder='John Doe'
                             />
                         </div>

                         {/* COMPANY NAME */}
                         <div className='flex flex-col items-start w-full mt-7 lg:mt-0'>
                             <p className='text-xs text-[#323F4B] font-semibold lg:text-xs xl:text-sm'>
                                 Company name (optional)
                             </p>
                             <input className='h-10 rounded-[4px] border-[1px] border-[#D0D5DD] mt-3.5 px-3.5 text-[11px] w-full md:w-[87%] lg:h-9 
                                               lg:w-[90%] lg:mt-2 xl:h-11 xl:text-sm' 
                              placeholder='John inc.'
                             />
                         </div>
                   </div>

                   <div className='flex flex-col items-center justify-between w-full md:flex-row lg:mt-4 xl:mt-5'>
                         {/* PHONE */}
                         <div className='flex flex-col items-start w-full mt-7 lg:mt-0'>
                             <p className='text-xs text-[#323F4B] font-semibold lg:text-xs xl:text-sm'>
                                  Phone
                             </p>
                             <input className='h-10 rounded-[4px] border-[1px] border-[#D0D5DD] mt-3.5 px-3.5 text-[11px] w-full md:w-[87%] lg:h-9 
                                               lg:w-[90%] lg:mt-2 xl:h-11 xl:text-sm' 
                              placeholder='123456789'
                             />
                         </div>

                         {/* EMAIL */}
                         <div className='flex flex-col items-start w-full mt-7 lg:mt-0'>
                             <p className='text-xs text-[#323F4B] font-semibold lg:text-xs xl:text-sm'>
                                 Email
                             </p>
                             <input className='h-10 rounded-[4px] border-[1px] border-[#D0D5DD] mt-3.5 px-3.5 text-[11px] w-full md:w-[87%] lg:h-9 
                                               lg:w-[90%] lg:mt-2 xl:h-11 xl:text-sm' 
                              placeholder='example@example.com'
                             />
                         </div>
                   </div>


                   {/* MESSAGE */}
                   <div className='flex flex-col items-start w-full mt-7 lg:mt-4 xl:mt-5'>
                        <p className='text-xs text-[#323F4B] font-medium lg:text-xs xl:text-sm'>
                             Message
                        </p>
                        <textarea className='rounded-[4px] border-[1px] border-[#D0D5DD] mt-3.5 px-3.5 py-3.5 text-[11px] w-full h-36 outline-[#6FC400] md:h-40
                                             md:w-[95%] lg:mt-2 lg:h-[105px] xl:h-[140px]' 
                         placeholder='Type message here'       
                         cols={50}
                        />
                   </div>



                   <motion.div 
                   initial={{scale: 1, backgroundColor: '#6FC400'}}
                   whileHover={{ backgroundColor: '#000000', scale: 1}}
                   whileTap={{ scale: 0.9 }}
                   transition={{ duration: 0.4, easing: "easeIn", }} 
                   className='flex items-center justify-center w-full rounded-[4px] h-10 bg-[#6FC400] mt-12 hover:border-[2px] border-[#6FC400] md:w-[300px] lg:w-full lg:mt-8 lg:h-11 xl:mt-10 xl:h-12'>
                        <p className='text-xs text-white font-medium xl:text-sm'>
                              Submit
                        </p>
                   </motion.div>


                   <div className='w-full mb-14 mt-2 lg:flex lg:mt-4 lg:mb-8 xl:mb-11'>
                        <p className='text-[10px] font-inter font-normal leading-5 text-[#374151] mt-7 md:text-[11px] lg:mt-3 xl:mt-8 xl:text-[12px] xl:font-medium xl:leading-5'>
                              <span className='font-bold'>For Businesses:</span> Do you have questions about how Morgen Green can help your company? Send us an email and we’ll get in touch, or phone 09116006006 between 08:00 and 17:00 Monday to Friday — we would be delighted to speak.
                        </p>
                   </div>

             </div>
        </div>

    </div>
  )
}

export default HomeContactUs
