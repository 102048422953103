import React from 'react'
import { IoSearchOutline } from "react-icons/io5";


const SearchBar = () => {
  return (
    <main className='flex items-center justify-start w-full font-inter px-5 my-10 md:w-[90%] md:my-12 lg:w-[80%] xl:w-[90%] xl:my-16 max-w-[1104px]'>
        <div className='relative w-full'>
            <input className='h-9 rounded-3xl border-[1px] border-[#D0D5DD] flex items-center w-full pl-6 text-[10px] text-[#130F26] placeholder:text-[#D0D5DD] 
                            outline-black md:h-10 lg:h-11 lg:text-xs xl:border-2 xl:text-sm xl:h-14 xl:pl-7 xl:rounded-[32px]'
            placeholder='Search our resources'
            />

            <div className='absolute top-1 right-1.5 flex items-center justify-center px-3.5 h-7 rounded-2xl text-white bg-[#130F26] md:h-8 md:px-[18px] md:right-2 
                            lg:h-9 lg:rounded-3xl lg:px-5 lg:right-2.5 xl:h-11 xl:top-1.5 xl:right-3 xl:px-6 xl:rounded-[30px]'>
                 <IoSearchOutline className='text-sm md:text-[15px] lg:text-[17px] xl:text-xl' />
                 <p className='text-[9px] font-light pl-1.5 md:text-[10px] md:pl-2 lg:text-xs xl:text-xs xl:font-normal'>
                    Search
                 </p>
            </div>
        </div>
    </main>
  )
}

export default SearchBar
