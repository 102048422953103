import React, { useEffect } from 'react'
import arrowDown from '../../assets/icons/arrowDown.svg'
import arrowUp from '../../assets/icons/arrowUp.svg'
import img1 from '../../assets/images/about1.png'
import img2 from '../../assets/images/about2.png'
import img3 from '../../assets/images/about3.png'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
import AOS from "aos";
import "aos/dist/aos.css";



const AboutContent1 = () => {

    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

  return (
    <main className='flex flex-col items-center justify-center w-full font-inter px-5 md:px-8 lg:px-16 xl:px-24 max-w-[1440px]'>
        <div className='flex flex-col items-start justify-start w-full my-9 md:flex-row md:justify-between md:mt-11 lg:items-center lg:mt-16'>
             {/* TEXTS AND BUTTONS */}
             <div className='flex flex-col items-start w-full lg:w-[50%] xl:w-[53%]'>
                 {/* ICON */}
                 <div className='flex items-center justify-start'>
                     <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                     <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
                 </div>

                 {/* DESCRIPTION */}
                 <div className='flex flex-col items-center justify-start md:items-start'>
                     <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full pt-2.5 lg:text-[17px] xl:text-2xl'>
                         About Morgen Green Limited
                     </p>

                     <p className='text-[12px] text-[#7B8794] font-normal leading-[26px] pt-3.5 lg:leading-[22px] xl:text-[16px] xl:leading-8 xl:pt-6'>
                         When it comes to fulfilling business requirements and challenges, <span className='text-[#6FC400]'>Morgen Green Limited</span> provides the world’s most advanced Information 
                         and Communication Technology products, services and solutions to a wide range of sectors. We run mission-critical ICT systems and manage products, 
                         services and solutions for JSE listed enterprise customers, key public sector organizations, parastatals and small to medium-sized Enterprise 
                         customers around the globe.
                         <br />
                         <br />
                        Founded & Managed by experienced technocrats, we combine insight, experience, global vendor partnerships, and extensive technological resources with
                        the commitment to deliver. Our unique business model is based upon configuring, integrating and maintaining innovative business solutions designed to 
                        meet our clients’ strategic and operational needs.
                        <br />
                        <br />
                        As organizations re-engineer themselves to be more responsive to customer needs, Morgen Green is well positioned to be a partner and co-innovator to 
                        businesses in their transformation journey by identifying new growth opportunities and facilitating their foray into new sectors and markets. We also 
                        champion optimized utilization of natural resources, capital and talent.
                     </p>

                     <motion.div onClick={()=>navigate({pathname: '/suppportPage', search: '?sectionId=LearnMore'})}
                     initial={{scale: 1, backgroundColor: '#6FC400'}}
                     whileHover={{ backgroundColor: '#000000', scale: 1}}
                     whileTap={{ scale: 0.9 }}
                     transition={{ duration: 0.4, easing: "easeIn", }}
                     className='flex items-center justify-center text-white bg-[#77C214] mt-4 text-[13px] h-12 w-full rounded cursor-pointer font-medium hover:bg-black 
                                hover:border-[2px] border-[#6FC400]  md:w-[160px] lg:w-[150px] xl:mt-5 xl:w-[180px] xl:h-16 xl:text-[15px]'>
                          Get in touch
                     </motion.div>
                 </div>
             </div>

             {/* IMAGE */}
             <div className='hidden lg:flex items-start justify-end w-[43%] xl:w-[40%]'>
                  {/* LEFT */}
                  <div className='flex flex-col items-center justify-start -mr-4 xl:mr-0'>
                      <div className='flex flex-col items-end justify-center'>
                          <div className='flex items-center justify-center bg-[#130F26] text-white rounded-md px-3 py-2'>
                              <p className='text-[9px] font-semibold font-sans text-white tracking-wider xl:text-[12px]'>
                                  Years of experience
                              </p>
                          </div>
                          <img className='-mr-5 w-6 -mt-1 object-cover xl:w-10 xl:-mr-9 xl:-mt-2'
                          src={arrowDown} alt='arrow_down' />
                      </div>

                      <motion.img 
                      initial={{ opacity: [0, 0, 0.2, 0.3, 0.5, 0.6], scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 1.5, easing: "easeIn", }}
                      className='w-[90%] mt-3.5 xl:w-full xl:mt-4'
                      src={img1} alt='about_1' 
                      />
                  </div>

                  {/* RIGHT */}
                  <div className='flex flex-col items-end justify-start xl:ml-5'>
                      <motion.img 
                      initial={{ opacity: [0, 0, 0.2, 0.3, 0.5, 0.6], scale: 0.8 }}
                      animate={{ opacity: 2, scale: 1 }}
                      transition={{ duration: 1.5, easing: "easeIn", }}
                      className='w-[90%] object-cover xl:w-full'
                      src={img2} alt='about_1' />

                      <motion.img 
                      initial={{ opacity: [0, 0, 0.2, 0.3, 0.5, 0.6], scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 2.5, easing: "easeIn", }}
                      className='w-[90%] object-cover mt-3.5 xl:w-full xl:mt-5'
                      src={img3} alt='about_1' />

                      <div className='flex flex-col items-start justify-center mt-5 xl:mt-7'>
                          <img className='-ml-6 w-6 -mb-1 object-cover xl:w-10 xl:-ml-9 xl:-mb-1.5'
                          src={arrowUp} alt='arrow_down' />
                          <div className='flex items-center justify-center bg-[#77C214] text-white rounded-md px-3 py-2'>
                              <p className='text-[9px] font-semibold font-sans text-white tracking-wider xl:text-[12px]'>
                                  Innovative
                              </p>
                          </div>
                      </div>
                  </div>
             </div>

        </div>

    </main>
  )
}

export default AboutContent1
