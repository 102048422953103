import React, { useEffect } from 'react'
import ibm from '../../assets/icons/ibm.svg'
import oracle from '../../assets/icons/oracle.svg'
import microsoft from '../../assets/icons/microsoft.svg'
import cisco from '../../assets/icons/cisco.svg'
import sap from '../../assets/icons/sap.svg'
import exchangePoint from '../../assets/icons/exchangePoint.svg'
import zte from '../../assets/icons/zte.svg'
import krent from '../../assets/icons/krent.svg'
import OAT from '../../assets/icons/oat2.svg'
import Jamb from '../../assets/icons/jamb.svg'
import AOS from "aos";
import "aos/dist/aos.css";




const Partners = () => {

      useEffect(() => {
            AOS.init();
            AOS.refresh();
       }, []);
      
  return (
    <div className='flex items-center justify-center w-full bg-[#ffffff] h-[90px] md:h-[80px] xl:h-[110px]'>

          <div className='flex flex-col items-center justify-center w-full'>
                <div className='flex items-center justify-center md:hidden'>
                    <p className='text-base font-sans font-semibold text-[#130F26] lg:text-lg xl:text-2xl'>
                        Our Partners
                    </p>
                </div>

                <div className='flex items-center justify-between w-full mt-2 px-5 overflow-x-auto no-scrollbar max-w-[1440px] md:mt-0 md:px-8 lg:px-16 xl:px-24'>
                     <div className='hidden md:flex items-center justify-start'>
                           <p className='hidden md:flex text-base font-sans font-semibold text-[#130F26] md:w-[100px] md:mr-14 lg:text-lg lg:w-[125px] xl:text-2xl xl:w-[175px]'>
                                 Our Partners
                           </p>
                     </div>

                     <div className='flex items-center w-full'>
                          <img data-aos="fade-up" data-aos-duration="400"
                          className='w-[49px] mr-7 md:w-[59px] md:mr-9 xl:w-[69px]'
                          src={ibm} alt='IBM' />

                          <img data-aos="fade-up" data-aos-duration="700"
                          className='w-[62px] mr-7 md:w-[72px] md:mr-9 xl:w-[82px]'
                          src={oracle} alt='Oracle' />

                          <img data-aos="fade-up" data-aos-duration="1000"
                          className='w-[72px] mr-7 md:w-[82px] md:mr-9 xl:w-[92px]'
                          src={microsoft} alt='Microsoft' />

                          <img data-aos="fade-up" data-aos-duration="1300"
                          className='w-[38px] mr-7 md:w-[48px] md:mr-9 xl:w-[58px]'
                          src={cisco} alt='Cisco' />

                          <img data-aos="fade-up" data-aos-duration="1600"
                          className='w-[29px] mr-7 md:w-[39px] md:mr-9 xl:w-[49px]'
                          src={sap} alt='SAP' />

                          <img data-aos="fade-up" data-aos-duration="1900"
                          className='w-[78px] mr-7 md:w-[88px] md:mr-9 xl:w-[98px]'
                          src={exchangePoint} alt='Exchange_Point' />

                          <img data-aos="fade-up" data-aos-duration="2200"
                          className='w-[28px] mr-6 md:w-[38px] md:mr-9 xl:w-[48px]'
                          src={zte} alt='ZTE' />

                          <img data-aos="fade-up" data-aos-duration="2500"
                          className='w-[86px] mr-6 md:w-[96px] lg:mr-7 xl:w-[106px]'
                          src={krent} alt='Krent' />

                          <img data-aos="fade-up" data-aos-duration="2800"
                          className='w-[45px] mr-8 md:w-[55px] md:mr-9 lg:w-[60px] lg:mr-10 xl:w-[58px]'
                          src={OAT} alt='OAT' />

                          <div className='mr-7'>
                               <img data-aos="fade-up" data-aos-duration="3000"
                               className='w-[38px] mr-16 md:w-[48px] md:mr-20 lg:w-[50px] lg:mr-28 xl:w-[58px]'
                               src={Jamb} alt='JAMB' />
                          </div>
                     </div>
                </div>

          </div>

    </div>
  )
}

export default Partners
