import React, { useEffect } from 'react'
import ServicesHero from '../components/servicesPage/ServicesHero'
import ServicesContent from '../components/servicesPage/ServicesContent'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import ServicesPartners from '../components/servicesPage/ServicesPartners'
import { useLocation } from 'react-router-dom'
import MetaTags from '../MetaTags'


const Services = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);

  return (
    <main className='flex flex-col items-center justify-center w-full' >
        <MetaTags
         title="Services" 
         description="" 
         link="/services"
          />
         <ServicesHero servColor='text-[#6FC400]' />
         <ServicesContent />
         <ServicesPartners />
         <Subscribe />
         <Footer />
    </main>
  )
}

export default Services
