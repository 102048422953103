import React, { useState } from 'react'
import innovation from '../../assets/images/about4.png'
import progress from '../../assets/images/about5.png'
import conscientiousness from '../../assets/images/about6.png'
import { motion } from 'framer-motion';


const AboutContent2 = () => {
    const [hover, setHover] = useState(false);

    const choose = [
        {
            id: 1,
            image: innovation,
            title: 'Innovation',
            desc: 'Constant reinvention of existing processes and discovery of new pathways to development through research and intelligent thought.',
        },
        {
            id: 2,
            image: progress,
            title: 'Cummulative Progress',
            desc: 'Long term, meaningful progress for the organization, the nation, the people, and the environment.',
        },
        {
            id: 3,
            image: conscientiousness,
            title: 'Conscientiousness',
            desc: "A conscious and unrelenting effort to make ethically sound, efficiency driven decisions that are beneficial for all parties involved.",
        },
    ]

    const handleMouseOver = (item) => {
        setHover(item)
    }

  return (
    <main className='flex flex-col items-center justify-start w-full font-inter px-5 my-9 md:my-11 max-w-[1440px] md:px-0 lg:my-14 xl:my-24'>

           <div className='flex flex-col items-center justify-start w-full md:px-8 lg:px-16 xl:px-24'>
               <div className='flex items-center justify-center'>
                    {/* ICON */}
                   <div className='flex items-center justify-center'>
                       <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                       <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
                   </div>
                   <p className='text-[15px] text-[#130F26] font-medium font-sans text-start ml-2 md:ml-2.5 lg:ml-3 lg:text-[17px] xl:ml-4 xl:text-2xl'>
                         Why choose Morgen Green Limited
                   </p>
               </div>

               <p className='text-[12px] text-[#7B8794] font-normal text-center w-full leading-[26px] pt-3.5 lg:w-[60%] lg:leading-[22px] xl:w-[55%] xl:text-[16px] xl:leading-8 xl:pt-5'>
                     Morgen Green Limited is a trusted partner of choice for global businesses looking to ‘differentiate at the front’ and ‘standardize at the core’ 
                     through technology interventions.
               </p>

           </div>


           {/* MOBILE SCREEN AND TABLET */}
           <div className='flex flex-col items-center justify-start flex-wrap w-full md:mt-7 md:flex-row md:items lg:hidden'>
                  {choose.map((item) => {
                    return(
                  <div key={item.id} className='relative flex flex-col items-center justify-end w-full h-[360px] mt-[18px] z-30 bg-cover bg-center 
                                bg-no-repeat md:h-[275px] md:w-[33.3%]' style={{backgroundImage: `url(${item.image})`}}>

                      <div className={`absolute flex flex-col items-center justify-end pb-4 w-full inset-x-0 bottom-0 bg-gradient-to-b from-transparent via-[#221f1f] 
                                     to-[#221f1f] h-[175px] md:h-[200px]`}>
                             <div className='flex flex-col items-center'>
                                 <p className={`text-xl font-sans text-start w-full pl-6 tracking-wider md:text-lg bg-gradient-to-t from-[#6FC400] via-[#adf450] to-[#ffffff] 
                                                text-transparent bg-clip-text`}>
                                      {item.title}
                                 </p>

                                 <p className={`text-[12px] leading-5 font-inter text-white text-start py-2 px-[5px] pl-6 md:text md:px-6 
                                                md:text-[11px] md:font-light md:pt-3`}>
                                      {item.desc}
                                 </p>
                             </div>
                      </div>

                  </div>
                      )
                  })}

            </div>


           {/* DESKTOP SIZE */}
           <div className='hidden lg:flex flex-col items-center justify-start flex-wrap w-full md:mt-7 md:flex-row lg:mt-9 xl:mt-12 max-w-[1440px]'>
                  {choose.map((item) => {
                    return(
                  <div onMouseOver={()=>handleMouseOver(item.id)} onMouseOut={()=>setHover(false)}
                  key={item.id} className='relative flex flex-col items-center justify-end w-full h-[360px] mt-[18px] z-30 bg-cover bg-center 
                                bg-no-repeat md:h-[275px] lg:h-[370px] xl:h-[507px] md:w-[33.3%]' style={{backgroundImage: `url(${item.image})`}}>

                    {hover === item.id 
                      ?<motion.div className={`absolute flex flex-col items-center justify-end pb-4 w-full inset-x-0 bottom-0 bg-gradient-to-b from-transparent via-[#221f1f] to-[#221f1f] 
                                  h-[175px] md:h-[200px] lg:h-[250px] lg:pb-7 xl:h-[350px] xl:pb-12`}>
                           
                            <motion.div 
                            initial={{opacity: 0, scale: 1}}
                            animate={{opacity: [0, 0.3, 0.3, 0.3, 0.3, 0.3, 1], scale: 1, y: ["125%", "0%"]}}
                            transition={{ duration: 0.8, delay: 0.3, easing: "easeInOut", }} 
                            className='flex flex-col items-center'>
                            <p className={`text-xl font-sans text-start w-full pl-6 tracking-wider md:text-xl lg:tracking-wider lg:pl-11 
                                           xl:text-[32px] xl:pl-14 bg-gradient-to-t from-[#6FC400] via-[#adf450] to-[#ffffff] text-transparent bg-clip-text xl:leading-10`}>
                                 {item.title}
                            </p>

                            <p className={`text-[12px] leading-5 font-inter text-white text-start py-2 px-[5px] pl-6 md:text md:px-6 
                                           md:text-[11px] md:pt-3 lg:pl-11 lg:text-[13px] lg:pt-4 xl:text-base xl:px-14 xl:pt-[25px]`}>
                                 {item.desc}
                            </p>
                            </motion.div>
                      </motion.div>

                      :<div className='absolute flex flex-col items-start justify-end h-[220px] w-full pb-6 z-30 md:pb-7 xl:pb-11'>
                            <div className='w-[40%] h-[1.5px] bg-[#6FC400] mb-[4px] opacity-100 md:w-[33%] md:mb-2 lg:h-[2px] xl:w-[31.5%] xl:mb-4 xl:h-[3.3px]'></div>
                            
                            <p className={`text-lg font-sans text-center text-white w-full tracking-wide md:text-xl md:text-start 
                         md:pl-6 lg:tracking-wider lg:pl-11 xl:text-[32px] xl:pl-14`}>
                                 {item.title}
                            </p>
                      </div>
                    }

                  </div>
                      )
                  })}

            </div>

    </main>
  )
}

export default AboutContent2
