import React from 'react'
import { motion } from 'framer-motion';


const Subscribe = () => {
  return (
    <div className='flex items-center justify-center w-full py-7 px-5 bg-[#6FC400] md:py-8 md:px-8 x lg:px-16 lg:py-10 xl:px-24'>
    <div className='flex flex-col items-center justify-center w-full'>
        <p className='text-lg font-sans font-medium text-white tracking-wider md:text-xl lg:text-[22px] xl:text-[27px]'>
             Subscribe to our newsletter
        </p>
        <p className='text-[10px] font-inter font-light text-white pt-1 md:text-[11px] lg:text- lg:pt-2 xl:text-[15px] xl:pt-4'>
            Be the first to get the latest updates from Morgen Green        
        </p>

        <div className='flex items-center justify-between w-full h-10 rounded-[100px] bg-white mt-5 shadow-lg px-1 py-1 md:w-[60%] 
                        md:h-11 lg:w-[50%] lg:p-[6px] lg:h-12 xl:h-[60px] xl:mt-9'>
            <input className='h-full w-full border-none outline-none px-6 text-[10px] rounded-[100px] text-[#130F26] font-inter 
                              font-normal xl:text-sm xl:px-8' 
            placeholder='Example@Example.com'
            />

            <motion.div 
            initial={{scale: 1}}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.4, easing: "easeIn", }} 
            className='flex items-center justify-center w-[43%] h-full rounded-[100px] cursor-pointer bg-[#130F26] md:w-[40%] lg:w-[32%]'>
               <p className='text-[11px] text-white font-inter font-normal xl:text-sm'>
                    Subscribe
               </p>
            </motion.div>
        </div>
    </div>
    </div>
  )
}

export default Subscribe
