import React, { useState, useEffect } from 'react';

const TypingEffect = ({ typingSpeed }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  
  useEffect(() => {
    const words = ['Nigeria', 'Africa'];

    const interval = setInterval(() => {
      const currentWord = words[currentWordIndex];
      if (!isDeleting) {
        if (currentCharIndex <= currentWord.length) {
          setDisplayText(currentWord.substring(0, currentCharIndex));
          setCurrentCharIndex((prevIndex) => prevIndex + 1);
        } else {
          setIsDeleting(true);
        }
      } else {
        if (currentCharIndex >= 0) {
          setDisplayText(currentWord.substring(0, currentCharIndex));
          setCurrentCharIndex((prevIndex) => prevIndex - 1);
        } else {
          setIsDeleting(false);
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }
      }
    }, typingSpeed);

    return () => clearInterval(interval);
  }, [currentWordIndex, currentCharIndex, isDeleting, typingSpeed]);

  return <div className='flex items-center justify-center pl-[6px] text-[#6FC400] md:pl-2 lg:pl-3 xl:pl-[14px]'>
           {displayText}
           <div className='h-full w-[3px] bg-[#6FC400] mt-1 md:w-1 md:mt-[5px] lg:h-14 lg:mt-[6px] lg:w-[3px] xl:w-1 xl:h-16 xl:ml-[1px]'></div>
        </div>;
};

export default TypingEffect;
