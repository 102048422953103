import React from 'react'
import ibm from '../../assets/icons/ibm.svg'
import oracle from '../../assets/icons/oracle.svg'
import microsoft from '../../assets/icons/microsoft.svg'
import cisco from '../../assets/icons/cisco.svg'
import sap from '../../assets/icons/sap.svg'
import exchangePoint from '../../assets/icons/exchangePoint.svg'
import zte from '../../assets/icons/zte.svg'
import krent from '../../assets/icons/krent.svg'
import oat from '../../assets/icons/oat.svg'
import jamb from '../../assets/icons/jamb.svg'


const ServicesPartners = () => {
  return (
    <main className='flex items-center justify-center w-full bg-gradient-to-r from-[#000620] to-[#303E45]'>
    <main className='flex flex-col items-center justify-center w-full font-inter pt-6 pb-5 px-5 md:px-8 lg:px-16 lg:pt-7 xl:px-24 xl:pt-9 xl:pb-7 max-w-[1440px]'>
        <p className='text-[#77C214] text-[10px] text-center md:text-[10px] lg:text-xs xl:text-sm'>
            Our collaborations and partners
        </p>
        <p className='text-[#ffffff] text-[12px] pt-1.5 text-center md:text-[13px] lg:pt-2 lg:text-base xl:text-xl xl:pt-3'>
            Who we have worked with, providing them 99.9% satisfaction
        </p>

        <div className='flex items-center justify-start w-full flex-wrap mt-3 max-w-[1440px] md:mt-5 md:justify-between xl:mt-7'>
                     <img className='h-4 mx-4 my-6 md:h-5 lg:h-6 lg:ml-0 lg:mr-12 lg:my-9 xl:h-9 xl:ml-0 xl:mx-16 xl:my-12'
                     src={ibm} alt='IBM' />

                     <img className='h-2.5 mx-5 my-6 md:h-3 md:mx-8 md:my-7 lg:mx-12 lg:my-9 xl:h-[18px] xl:mx-16 xl:my-12'
                     src={oracle} alt='Oracle' />

                     <img className='h-4 mx-5 my-6 md:h-[18px] md:mx-8 md:my-7 lg:h-5 lg:mx-12 lg:my-9 xl:h-7 xl:mx-16 xl:my-12'
                     src={microsoft} alt='Microsoft' />

                     <img className='h-5 mx-5 my-6 md:h-6 md:mx-8 md:my-7 lg:h-7 lg:mx-12 lg:my-9 xl:h-9 xl:mx-16 xl:my-12'
                     src={cisco} alt='Cisco' />

                     <img className='h-5 mx-5 my-6 md:h-6 md:mx-8 md:my-7 lg:h-7 lg:mx-12 lg:my-9 xl:h-9 xl:mx-16 xl:my-12'
                     src={sap} alt='SAP' />

                     <img className='h-4 mx-5 my-6 md:h-[18px] md:mx-8 md:my-7 lg:h-5 lg:ml-0 lg:mx-12 lg:my-9 xl:h-[26px] xl:ml-0 xl:mx-16 xl:my-12'
                     src={exchangePoint} alt='Exchange_Point' />

                     <img className='h-4 mx-5 my-6 md:h-[22px] md:mx-8 md:my-7 lg:h-8 lg:mx-12 lg:my-9 xl:h-9 xl:mx-16 xl:my-12'
                     src={zte} alt='ZTE' />

                     <img className='h-7 mx-5 my-6 md:h-8 md:mx-8 md:my-7 lg:h-9 lg:mx-12 lg:my-9 xl:h-12 xl:mx-16 xl:my-12'
                     src={krent} alt='Krent' />

                     <img className='h-8 mx-5 my-6 md:h-8 md:mx-8 md:my-7 lg:h-9 lg:mx-12 lg:my-9 xl:h-12 xl:mx-16 xl:my-12'
                     src={oat} alt='Krent' />

                     <img className='h-7 mx-5 my-6 md:h-9 md:mx-8 md:my-7 lg:h-10 lg:mx-12 lg:my-9 xl:h-16 xl:mx-16 xl:my-12'
                     src={jamb} alt='Krent' />
       </div>
    </main>
    </main>
  )
}

export default ServicesPartners
