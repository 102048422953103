import React, { useEffect, useState } from 'react'
import globe from '../../assets/images/earth.jpg'


const HomeSection6 = () => {
  const [employees, setEmployees] = useState(0);
  const [locations, setLocations] = useState(0);
  const [satisfaction, setSatisfaction] = useState(50);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsInView(true);
      }
    }, {
      threshold: 1.0,
    });

    observer.observe(document.getElementById('my-component'));

    // return () => {
    //   observer.unobserve(document.getElementById('my-component'));
    // };
  }, []);

  useEffect(() => {
    if (isInView) {
      const employeesId = setInterval(() => {
        if (employees <= 29) {
          setEmployees(employees + 1);
        } else {
          clearInterval(employeesId);
        }
      }, 100);

      const locationsId = setInterval(() => {
        if (locations <= 2) {
          setLocations(locations + 1);
        } else {
          clearInterval(locationsId);
        }
      }, 100);

      const satisfactionId = setInterval(() => {
        if (satisfaction <= 98) {
          setSatisfaction(satisfaction + 1);
        } else if (satisfaction === 99) {
          setSatisfaction(99.9);
        } else {
          clearInterval(satisfactionId);
        }
      }, 100);

      return () => {
        clearInterval(employeesId);
        clearInterval(locationsId);
        clearInterval(satisfactionId);
      };
    }
  }, [isInView, employees, locations, satisfaction]);



  return (
    <div id="my-component" className='flex flex-col items-center w-full h-[450px] px-5 pt-[68px] bg-cover bg-center bg-no-repeat md:h-[450px] md:px-8 
                    md:pt-28 lg:px-16 lg:pt-20 lg:h-[450px] xl:pt-28 xl:h-[590px] xl:px-24' style={{backgroundImage: `url(${globe})`}}>

         <p className='text-[#ffffff] text-lg text-center font-sans font-normal tracking-wide pt-3 md:w-[70%] md:text-xl lg:text-2xl
                         lg:w-[68%] lg:pt-5 xl:text-3xl xl:w-[60%] xl:pt-6'>
              Assisting businesses of all sizes, from small startups
              to large enterprises like yours.
         </p>

         <p className='text-[#ffffff] text-[11px] font-inter font-extralight font- text-center pt-5 w-full leading-5 md:w-[65%] 
                          lg:pt-4 lg:text-[12px] lg:w-[50%] xl:text-sm xl:leading-6 xl:pt-6 xl:w-[47%]'>
               Morgen Green Limited combines insight, experience, global vendor partnerships, and extensive technological resources with the commitment to deliver.
         </p>

         <div className='flex items-start justify-between w-[85%] mt-12 md:mt-20 md:w-[57%] lg:w-[45%] xl:w-[40%] xl:mt-24'>
               <div className='flex flex-col items-center justify-between'>
                      <p className='text-3xl font-black font-inter text-[#6FC400] md:text-4xl lg:text-4xl xl:text-5xl'>{employees}+</p>
                      <p className='text-[9px] font-medium font-inter text-[#FFFFFF] mt-[10px] md:mt-3 lg:text-[9px] lg:font-medium 
                      xl:text-[11px]'>
                        EMPLOYEEES
                      </p>
               </div>

               <div className='flex flex-col items-center justify-between'>
                      <p className='text-3xl font-black font-inter text-[#6FC400] md:text-4xl lg:text-4xl xl:text-5xl'>{locations}</p>
                      <p className='text-[9px] font-medium font-inter text-[#FFFFFF] mt-[10px] md:mt-3 lg:text-[9px] lg:font-medium 
                                     xl:text-[11px]'>
                        LOCATIONS
                    </p>
               </div>

               <div className='flex flex-col items-center justify-between'>
                      <p className='text-3xl font-black font-inter text-[#6FC400] md:text-4xl lg:text-4xl xl:text-5xl'>{satisfaction}%</p>
                      <p className='text-[9px] font-medium font-inter text-[#FFFFFF] mt-[10px] md:mt-3 lg:text-[9px] lg:font-medium 
                      xl:text-[11px]'>
                          SATISFACTION
                      </p>
               </div>
         </div>

    </div>
  )
}

export default HomeSection6
