import React from 'react';
import { Helmet } from 'react-helmet';


const MetaTags = ({ title, description, link }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={link} />
    </Helmet>
  );
};


export default MetaTags;