import React from 'react'
import { IoDiceOutline } from "react-icons/io5";
import { LiaCompressArrowsAltSolid } from "react-icons/lia";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { IoWifiOutline } from "react-icons/io5";
import { Link } from 'react-router-dom'


const TabsContent = ({appDevColor, netColor, itColor, telColor, title, desc, image}) => {

  return (
    <main className='flex flex-col items-start justify-start w-full font-inter py-5 px-5 md:px-8 md:py-8 lg:py-8 lg:px-16 xl:px-24 max-w-[1440px]'>
        {/* TABS */}
        <div className='flex flex-col items-start w-full text-[#130F26] md:flex-row md:justify-between md:items-center lg:justify-center'>
             <Link to='/appDev' className='flex items-center justify-start py-2 lg:px-3.5 xl:px-5'>
                 <IoDiceOutline className={`text-[23px] md:text-xl xl:text-2xl ${appDevColor}`} />
                 <p className={`text-xs pl-2.5 font-medium md:text-[10px] md:pl-1.5 lg:text-xs xl:text-[15px] xl:pl-2.5 ${appDevColor}`}>
                     Enterprise Application Development
                 </p>
             </Link>

             <Link to='/netServices' className='flex items-center justify-start py-2 lg:px-3.5 xl:px-5'>
                 <LiaCompressArrowsAltSolid className={`text-[23px] md:text-xl xl:text-2xl ${netColor}`} />
                 <p className={`text-xs pl-2.5 font-medium md:text-[10px] md:pl-1.5 lg:text-xs xl:text-[15px] xl:pl-2.5 ${netColor}`}>
                      Network Services
                 </p>
             </Link>

             <Link to='/support' className='flex items-center justify-start py-2 lg:px-3.5 xl:px-5'>
                 <IoExtensionPuzzleOutline className={`text-[23px] md:text-xl xl:text-2xl ${itColor}`} />
                 <p className={`text-xs pl-2.5 font-medium md:text-[10px] md:pl-1.5 lg:text-xs xl:text-[15px] xl:pl-2.5 ${itColor}`}>
                      Managed IT Services
                 </p>
             </Link>

             <Link to='/telecomServices' className='flex items-center justify-start py-2 lg:px-3.5 xl:px-5'>
                 <IoWifiOutline className={`text-[23px] md:text-xl xl:text-2xl ${telColor}`} />
                 <p className={`text-xs pl-2.5 font-medium md:text-[10px] md:pl-1.5 lg:text-xs xl:text-[15px] xl:pl-2.5 ${telColor}`}>
                      Telecommunication Services
                 </p>
             </Link>
        </div>


        <div className='flex flex-col items-start justify-start w-full mt-9 md:flex-row md:justify-between md:mt-14 lg:mt-16 xl:items-center'>
             {/* TEXTS AND BUTTONS */}
             <div className='flex flex-col items-start w-full md:w-[45%] lg:w-[44%] xl:w-[49%]'>
                 {/* ICON */}
                 <div className='flex items-center justify-start'>
                     <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                     <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
                 </div>

                 {/* DESCRIPTION */}
                 <div className='flex flex-col items-center justify-start md:items-start'>
                     <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full pt-2.5 lg:text-[17px] xl:text-2xl'>
                         {title}
                     </p>

                     <p className='text-[12px] text-[#7B8794] font-normal font-inter leading-[26px] pt-3.5 lg:leading-[22px] xl:text-[16px] xl:leading-8 xl:pt-6'>
                          {desc}
                     </p>

                     <Link to={{ pathname: '/suppportPage', search: '?sectionId=LearnMore'}}
                     className='flex items-center justify-center text-white bg-[#77C214] mt-4 text-[13px] h-12 w-full font-inter rounded font-medium md:w-[160px] lg:w-[150px] xl:mt-5 xl:w-[180px]
                                        xl:h-16 xl:text-[15px]'>
                          Get in touch
                     </Link>
                 </div>
             </div>

             {/* IMAGE */}
             <div className='flex flex-col items-start w-full mt-6 md:w-[45%] lg:w-[53%] xl:w-[49%]'>
                  <img className='object-cover w-full md:h-[375px] md:rounded-2xl md:border-[1px] md:border-[#77C214] lg:border-none lg:rounded-none lg:h-full'
                  src={image} alt='desc-img' loading='lazy' />
             </div>
        </div>

    </main>
  )
}

export default TabsContent
