import React from 'react'
import experience from '../../assets/icons/experience.svg'
import professionalism from '../../assets/icons/professionalism.svg'
import vibrancy from '../../assets/icons/vibrancy.svg'


const WhatWeOffer = () => {

    const data = [
        {
            id: 1,
            image: <img className='h-[23px] md:h-[28px] xl:h-[34px]' src={experience} alt='Experience' />,
            title: 'Experience',
            desc: 'We have several years experience providing expert IT services & solutions.',
        },
        {
            id: 2,
            image: <img className='h-[23px] md:h-[28px] xl:h-[34px]' src={professionalism} alt='Professionalism' />,
            alt: 'Professionalism',
            title: 'Professionalism',
            desc: 'Our technology specialists ensure you get the optimum results.',
        },
        {
            id: 3,
            image: <img className='h-[25px] md:h-[28px] xl:h-[34px]' src={vibrancy} alt='Vibrancy' />,
            alt: 'Vibrancy',
            title: 'Vibrancy',
            desc: 'A young, enthusiastic team of IT geeks are always here to help.',
        },
    ]

  return (
    <div className='flex flex-col items-center w-full bg-[#ffffff] py-8'>
         <div className='flex flex-col items-center justify-center w-full'>
             <p className='text-[11px] font-sans font-normal text-[#6FC400] tracking-wide md:text-xs lg:text-base'>
                  What makes us special?
             </p>
             <p className='text-xl font-sans font-medium text-[#130F26] tracking-wide mt-1 md:text-2xl lg:text-[25px] lg:mt-[6px]
                           xl:text-3xl xl:tracking-wider'>What we offer</p>
         </div>


         <div className='flex item-start justify-center flex-wrap w-full mt-8 md:justify-between md:px-8 lg:mt-9 lg:px-16 xl:mt-11 xl:px-24 max-w-[1440px]'>
             {data.map((item) => {
                return(
             <div key={item.id} className={`flex flex-col items-center w-[47%] md:w-[23%] lg:w-[20%] xl:w-[18%] ${item.id === 3 && 'mt-8 w-[47%] md:mt-0'}`}>
                {item.image}
                <p className='text-[#130F26] text-xs font-inter font-bold pt-3 md:text-[13px] lg:text-[13px] lg:pt-5 xl:text-base xl:pt-6'>
                    {item.title}
                </p>

                <p className='text-[#374151] text-[10px] font-inter font-normal text-center pt-[6px] w-full lg:pt-3 lg:text-[11px] lg:leading-5 xl:text-sm xl:leading-6 xl:pt-2'>
                   {item.desc}
                </p>
             </div>
            )
        })}

         </div>

    </div>
  )
}

export default WhatWeOffer
