import React from 'react'

const Comments = () => {

    const comments = [
        {
            id: 1,
            name: 'Christy Bolter',
            date: '08/06/24',
            comment: 'I actually loved the article, very insighful. i learnt a lot of new stuff and would be coming back for more',
        },
        {
            id: 2,
            name: 'Christy Bolter',
            date: '08/06/24',
            comment: 'I actually loved the article, very insighful. i learnt a lot of new stuff and would be coming back for more',
        },
    ]


  return (
    <main className='flex flex-col items-start font-inter w-full px-5 mb-16 md:mb-28 md:px-8 lg:px-16 lg:mb-32 xl:px-24 xl:mb-44 max-w-[1440px]'>
          <p className='text-sm text-[#130F26] font-semibold font-sans text-start w-full lg:text-[15px] xl:text-lg'>
              Recent Comment
          </p>

          <div className='flex flex-col items-start w-full mt-2.5 lg:mt-4'>
               {comments.map((item) => (
               <div key={item.id} className='flex flex-col items-start w-full border-[1px] border-[#CCCCCC] rounded-[15px] py-3.5 px-3.5 my-2 md:my-2.5 md:py-5 md:pb-7 
                                             md:rounded-2xl lg:rounded-3xl lg:my-3 xl:w-[90%] xl:px-5 xl:pb-9 xl:py-[22px] xl:rounded-[30px] xl:my-4'>
                   <div className='flex items-center justify-between w-full'>
                       <p className='text-[11px] text-[#130F26] font-semibold text-start md:text-xs xl:text-sm'>
                           {item.name}
                       </p>
                       <p className='text-[9px] text-[#727272] font-normal text-end md:text-[10px] xl:text-xs'>
                           {item.date}
                       </p>
                   </div>
                   <p className='text-[11px] text-[#292929] font-normal text-start w-full mt-5 leading-5 md:text-xs md:leading-5 md:w-[90%] lg:w-[95%] xl:text-sm xl:leading-6'>
                       {item.comment}
                   </p>
               </div>
               ))}
          </div>



          <p className='text-xs text-[#130F26] font-semibold text-start w-full mt-7 md:mt-8 md:text-sm lg:mt-9 xl:text-base'>
             Put down your thoughts by writing a comment
          </p>

          <textArea className='flex flex-col items-start w-full text-[10px] border-[1px] border-[#CCCCCC] rounded-[15px] py-3.5 px-3.5 my-3 h-24 outline-[#727272] md:py-4
          md:rounded-2xl md:h-32 md:mt-4 md:text-[11px] md:w-[85%] lg:rounded-3xl lg:h-36 lg:w-[90%] xl:px-5 xl:py-[22px] xl:h-[185px] xl:text-[13px] xl:w-[80%]'
          placeholder='Write down your thoughts'
           />

           <button className='text-white text-[10px] font-medium bg-[#6FC400] text-center h-9 rounded mt-3.5 px-4 md:px-5 xl:mt-4 xl:text-xs xl:h-10 xl:px-5'>
               Post comment
           </button>

    </main>
  )
}

export default Comments
