import React, { useEffect, useState } from 'react'
import ServicesHero from '../components/servicesPage/ServicesHero'
import Subscribe from '../components/general/Subscribe'
import Footer from '../components/general/Footer'
import { SlArrowRight } from "react-icons/sl";
import TabsContent from '../components/servicesPage/TabsContent';
import netImg from '../assets/images/netImg.png'
import { IoWalletOutline } from "react-icons/io5";
import { HiOutlineArrowTrendingUp } from "react-icons/hi2";
import { LuClipboardList } from "react-icons/lu";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation } from 'react-router-dom';




const NetServices = () => {
  const location = useLocation();
  const sectionId = new URLSearchParams(location.search).get('sectionId');
  const { pathname } = useLocation();

  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    window.scrollTo(0, 0);
  }, [sectionId, pathname]);

  const tabsData = [
    {
      id: 1,
      title: 'Income Generation',
      activeIcon: <IoWalletOutline className={`text-[16px] text-white`} />,
      inActiveIcon: <IoWalletOutline className={`text-[16px] text-[#D0D5DD]`} />,
      desc: `Traditional interest-bearing bonds pay interest on a regular basis, typically semi-annually, quarterly, or monthly. The payments on these bonds are 
             fixed, which means the amount you receive with each payment generally remains the same. This predictability makes traditional interest-bearing bonds 
             an attractive option for investors seeking stable and steady returns.`
    },
    {
      id: 2,
      title: 'Growth',
      activeIcon: <HiOutlineArrowTrendingUp className={`text-[16px] text-white`} />,
      inActiveIcon: <HiOutlineArrowTrendingUp className={`text-[16px] text-[#D0D5DD]`} />,
      desc: `Though bonds are often used for their ability to generate income, it is also possible for them to turn into growth investments. This happens when interest 
             rates drop below the interest rate the bond is receiving, which makes it an appealing investment for other investors and allows the investor holding the bond 
             to sell the bond at a premium.`
    },
    {
      id: 3,
      title: 'Factors to Consider',
      activeIcon: <LuClipboardList className={`text-[16px] text-white`} />,
      inActiveIcon: <LuClipboardList className={`text-[16px] text-[#D0D5DD]`} />,
      desc: `Investing in fixed-income securities involves certain risks, such as market risk if sold prior to maturity and credit risk especially if investing in high yield 
             bonds, which have lower ratings and are subject to greater volatility. All fixed-income investments may be worth less than original cost upon redemption or maturity.`
    },
  ]



  const [open, setOpen] = useState([tabsData[0].id]);

  const [tab, setTab] = useState(1);
  
  const handleTab = (item) => {
    setOpen((prevState) =>
      prevState.includes(item)
        ? prevState.filter((tabId) => tabId !== item)
        : [...prevState, item]
    );

  }


  return (
    <main className='flex flex-col items-center justify-center font-inter w-full'>
        <ServicesHero appDev={'Network Services'} appDevIcon={<SlArrowRight className={`text-white text-[10px] mx-1 lg:mx-1.5`} />} /> 
        <TabsContent 
        netColor='text-[#77C214]' 
        title='Network Services' 
        desc={`As you may know, Change is the Key Driver for new technology acquisition & adoption in organizations. Today, Network & Communication Infrastructure
             is known to be the foundation platform for IT & business success – the ability to connect Applications with Employees, Customers & Partners quickly &
              efficiently is the need of the hour. The ability to connect applications with employees, customers, and partners quickly and efficiently is more crucial
               than ever. Efficient communication networks ensure that information flows smoothly and securely, facilitating real-time collaboration and decision-making.`}
        image={netImg}
        />

        <div className='flex flex-col items-center justify-center w-full mt-8 mb-14 px-5 md:px-8 md:mt-14 md:mb-16 lg:mt-20 lg:mb-20 lg:px-16 xl:mt-28'>
            <div className='flex items-center justify-center'>
                {/* ICON */}
               <div className='flex items-center justify-center'>
                  <hr className='border-[0.01rem] border-[#77C214] w-10 rounded-md xl:w-14' />
                  <div className='h-2 w-2 rounded-full bg-[#77C214]'></div>
               </div>
               <p className='text-[15px] text-[#130F26] font-medium font-sans text-start w-full pl-2 md:pl-3 lg:text-[17px] xl:text-2xl xl:pl-5'>
                    Here is what we do for you
               </p>
           </div>

           <p className='text-[12px] text-[#7B8794] font-normal text-center leading-[26px] pt-3 w-full md:pt-5 md:w-[85%] lg:leading-[22px] lg:pt-[22px] lg:w-[75%] 
                         xl:text-[16px] xl:leading-8 xl:pt-7 xl:w-[65%]'>
                         Morgen Green’s Network Services is a trusted partner at the forefront of Network Transformation as well as Network Management leveraging new technologies and delivering them efficiently. 
                         We are known to also provide complete Life cycle services for Network which includes Consult, Implement & Manage.
           </p>

           {/* MOBILE DROPDOWN */}
           <div className='flex flex-col items-center w-full mt-4 mb-5 md:hidden'>
               {tabsData.map((item) => (
               <div key={item.id} className={`flex flex-col items-center w-full border-[1px] rounded-xl mt-4 py-1.5 ${open.includes(item.id) ? 'border-[#D0D5DD] mb-1' : 'border-none'}`}>
                   <div onClick={()=>handleTab(item.id)} className='flex items-center justify-between w-full px-2.5'>
                       <div className='flex items-center justify-start'>
                           <div className={`flex items-center justify-center h-[29px] w-[29px] rounded-full border-[3px] ${open.includes(item.id) ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                               {
                                open.includes(item.id) 
                                ?item.activeIcon
                                :item.inActiveIcon
                               }
                           </div>
                           <p className={`text-sm font-medium font-sans pl-2.5 ${open.includes(item.id) ? 'text-[#101828]' : 'text-[#7B8794]'}`}>
                               {item.title}
                           </p>
                       </div>

                       {open.includes(item.id)
                        ?<IoIosArrowUp className='text-[#101828] text-base' />
                        :<IoIosArrowDown className='text-[#7B8794] text-base' />
                       }
                   </div>

                   <hr className='border-[1px] border-[#D0D5DD] w-full mt-[5px] opacity-35' />

                   {open.includes(item.id) &&
                   <p className='text-[12px] text-[#7B8794] font-normal leading-[26px] pt-2.5 px-2.5'>
                        {item.desc}
                   </p>
                   }
               </div>
              ))}
           </div>


           {/* TABLET SIZE>>> DROPDOWN */}
           <div className='hidden md:flex flex-col items-center w-[85%] mt-10 mb-5 border-[1px] border-[#D0D5DD] border-x-[#77C214] border-b-[#77C214] border-t-[#77C214] rounded-2xl lg:w-[75%] xl:w-[65%] xl:mt-14 xl:border-[1.6px]'>
              {/* TABS */}
              <div className='flex items-center justify-center w-full'>
                  <div onClick={()=>setTab(1)}
                  className={`flex flex-col items-center w-[33.5%] h-[85px] py-3.5 rounded-tl-2xl xl:h-28 xl:py-4 ${tab === 1 ? 'bg-white xl:border-r-[1.5px] xl:border-r-[#77C214]' 
                              : tab === 2 ? 'bg-[#F0F0F0] border-b-[#77C214] border-[2px] border-t-[#D0D5DD] -mt-0.5 -ml-0.5 xl:border-[2.5px] xl:-mt-1' 
                              : 'bg-[#F0F0F0] border-b-[#77C214] border-[1px] border-t-[#D0D5DD] border-t-[2px] -mt-[1px] border-l-[#D0D5DD] border-l-2 -ml-[3px] xl:border-b-2'}`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 1 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <IoWalletOutline className={`text-[16px] xl:text-2xl ${tab === 1 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal pt-0.5 font-sans xl:text-base xl:font-medium xl:pt-1.5 ${tab === 1 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                        Income Generation
                     </p>
                  </div>


                  <div onClick={()=>setTab(2)}
                  className={`flex flex-col items-center w-[33.35%] h-[85px] py-3.5 border-[2px] border-[#D0D5DD] -mt-[0.8px] border-l-[1px] border-b-[1px] border-b-[#77C214] 
                              border-r-[0.2px] xl:h-28 xl:py-4 ${tab === 1 ? 'border-l-[#77C214] bg-[#F0F0F0] -mt-0.5 xl:border-b-2' 
                              : tab === 2 ? 'border-b-white border-x-[#77C214] border-t-[#77C214] border-t-[1px] bg-white xl:border-x-2' 
                              : 'border-r-[#77C214] bg-[#F0F0F0] xl:border-b-2 xl:border-r-2 xl:-mt-0.5'}`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 2 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <HiOutlineArrowTrendingUp className={`text-[16px] xl:text-2xl ${tab === 2 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal pt-0.5 font-sans xl:text-base xl:font-medium xl:pt-1.5 ${tab === 2 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                         Growth
                     </p>
                  </div>


                  <div onClick={()=>setTab(3)}
                  className={`flex flex-col items-center w-[33.5%] h-[85px] py-3.5 border-[2px] border-[#D0D5DD] -mt-[0.8px] rounded-tr-2xl -mr-[1.5px] border-b-[#77C214] border-b-[1px] border-l-[0.2px] xl:h-28 xl:py-4
                            ${tab === 1 ? 'bg-[#F0F0F0] -mt-0.5 xl:border-b-2 xl:border-t-2 xl:border-r-2' 
                            : tab === 2 ? 'bg-[#F0F0F0] border-t-2 xl:border-b-2 xl:border-t-[2.5px] xl:-mt-0.5' 
                            : 'bg-white border-b-0 border-b-white border-r-0 border-t-[1px] border-t-[#77C214] border-l-[#77C214] border-l-0 xl:border-r-2 xl:border-r-[#77C214]' }`}>
                     <div className={`flex items-center justify-center h-[30px] w-[30px] rounded-full border-[3px] xl:h-[45px] xl:w-[45px] ${tab === 3 ? 'bg-[#77C214] border-[#CEE9AA]' : 'bg-[#7B8794] border-[#CFD4D8]'}`}>
                        <LuClipboardList className={`text-[16px] xl:text-2xl ${tab === 3 ? 'text-[#ffffff]' : 'text-[#D0D5DD]'}`} />
                     </div>
                     <p className={`text-[13px] font-normal pt-0.5 font-sans xl:text-base xl:font-medium xl:pt-1.5 ${tab === 3 ? 'text-[#130F26]' : 'text-[#7B8794]'}`}>
                         Factors to Consider
                     </p>
                  </div>
              </div>

              <p className='text-[11px] text-[#374151] font-normal text-start leading-6 py-6 w-full px-9 lg:leading-[22px] xl:px-14 xl:py-8 xl:text-[14px] xl:leading-8'>
                  {tab === 1
                   ?`Traditional interest-bearing bonds pay interest on a regular basis, typically semi-annually, quarterly, or monthly. The payments on these bonds are fixed, 
                     which means the amount you receive with each payment generally remains the same. This predictability makes traditional interest-bearing bonds an attractive 
                     option for investors seeking stable and steady returns.`

                   :tab === 2
                   ?`Though bonds are often used for their ability to generate income, it is also possible for them to turn into growth investments. This happens when interest 
                     rates drop below the interest rate the bond is receiving, which makes it an appealing investment for other investors and allows the investor holding the bond 
                     to sell the bond at a premium.`

                   :`Investing in fixed-income securities involves certain risks, such as market risk if sold prior to maturity and credit risk especially if investing in high yield 
                     bonds, which have lower ratings and are subject to greater volatility. All fixed-income investments may be worth less than original cost upon redemption or maturity.`
                  }
              </p>
                
           </div>

        </div>

        <Subscribe />
        <Footer />
    </main>
  )
}

export default NetServices
